import {Form, Input, Select} from "antd";
import {useRequestPaymentLineStore, useTravelExpenseStore} from "../../../stores";
import {useEffect} from "react";

export const EmployeeInformation = () => {

    const [form] = Form.useForm();
    const {personnel, company} = useTravelExpenseStore();

    const {line, setData} = useRequestPaymentLineStore();

    const handleEmployeeChange = (value) => {
        const employee = personnel.find(person => person.uid === value)
        form.setFieldsValue({position: employee.position_title, fullname: employee.fullname})
        handleFormChange()
    }

    const handleFormChange = () => {
        setData(form.getFieldsValue());
    }

    useEffect(() => {
        if (line.length === 0){
            form.resetFields();
        } else {
            form.setFieldsValue(line)
        }
    }, [line])

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Tên nhân viên"
                    name="uid"
                    className="narrow-form-item"
                    size="small"
                >
                    <Select
                        showSearch
                        placeholder="Tên nhân viên"
                        style={{width: '100%'}}
                        options={
                            personnel.map(person => ({
                                label: person.fullname,
                                value: person.uid
                            }))
                        }
                        onChange={handleEmployeeChange}
                        onBlur={handleFormChange}
                    />
                </Form.Item>
                <Form.Item
                    hidden
                    name="fullname"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Vị trí"
                    name="position"
                    className="narrow-form-item"
                >
                    <Input
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    label="Công ty ký HĐLĐ"
                    name="company"
                    onBlur={handleFormChange}
                >
                    <Select
                        placeholder="Tên công ty"
                        options={
                            company.map(item => ({
                                label: item.name,
                                value: item.name
                            }))
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    )
}
