import {Button, Card, Input} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";

const {TextArea} = Input;
const FormNotes = () => {
    const setLoading = UseLoading(state => state.setLoading);

    const [phanloaiNotes, setPhanloaiNotes] = useState('');
    const [ctpNotes, setCtpNotes] = useState('');

    useEffect(() => {
        setLoading(true);
        api.get('/master/notes/get').then((response) => {
            if (response){
                setPhanloaiNotes(response.find(x => x.type === 'phanloai')?.note);
                setCtpNotes(response.find(x => x.type === 'ctp')?.note);
            }
        }).finally(() => {
            setLoading(false)
        });

    }, []);
    const updateNote = (type) => {
        let payload = null;
        if (type === 'phanloai'){
            payload = {type: 'phanloai', note: phanloaiNotes}
        } else if (type === 'ctp'){
            payload = {type: 'ctp', note: ctpNotes}
        }

        setLoading(true);
        api.post('/master/notes/add', payload).then((response) => {
            if (response)
                notify('info', response);

        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <Card
                title="Ghi chú Phân loại thanh toán"
                extra={
                    <Button
                        danger
                        onClick={() => updateNote('phanloai')}
                        icon={<SaveOutlined />}>Lưu</Button>
                }
            >
                <TextArea
                    style={{
                        minHeight: 150,
                        resize: 'none',
                    }}
                    onChange={(e) => setPhanloaiNotes(e.target.value)}
                    value={phanloaiNotes}
                />
            </Card>
            <Card
                title="Hướng dẫn danh mục hồ sơ thanh toán công tác phí"
                extra={
                    <Button danger onClick={() => updateNote('ctp')} icon={<SaveOutlined />}>Lưu</Button>
                }
                className="mt-3"
            >
                <TextArea
                    style={{
                        minHeight: 150,
                        resize: 'none',
                    }}
                    onChange={(e) => setCtpNotes(e.target.value)}
                    value={ctpNotes}
                />
            </Card>
        </div>
    )
}

export default FormNotes