import { doReadNumber, ReadingConfig } from 'read-vietnamese-number'

export function formatNumber(number, digits = 0) {
    if (typeof number !== 'number') {
        return number; // Return as-is if not a number
    }

    //Even if digits is 0, we still want to format the number with commas if number has decimal places
    if (number % 1 !== 0) {
        digits = 2;
    }

    if (digits > 0){
        number = number.toFixed(digits); // Limit decimal places to 2
    }


    const parts = number.toString().split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1] ? `.${parts[1]}` : ''; // Add decimal part if it exists

    return `${integerPart}${decimalPart}`;
    //return number.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

export const readNumber = (number, unit = 'đồng') => {
    const readConfig = new ReadingConfig();
    readConfig.unit = [unit];

    let toVietnamese

        try {
            toVietnamese = doReadNumber(readConfig, number.toString() || '0');
        } catch (err) {
            toVietnamese = 'Không thể đọc số này: ' + number;
        }
    //capitalize first letter
    return toVietnamese.charAt(0).toUpperCase() + toVietnamese.slice(1);
}