import {Button, Flex, Grid, Popover, Space, Table, Tooltip} from "antd";
import {useRowSelection, useTravelExpenseStore} from "../stores";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import InputEmployeeIdSearch from "./InputEmployeeIdSearch";
import {IconBookmark, IconGeoLocation, IconPersonCard} from "../../Icons";
import {notify} from "../../../Helpers/toast-helper";
import {useEffect} from "react";

const Step1Personnel = ({activeStep}) => {
    const thisStep = 'personnel';
    const {personnel, removePersonnel, detailSheet} = useTravelExpenseStore();

    const breakpoints = Grid.useBreakpoint();

    const [selectedRowKeys, setSelectedRowKeys, rowSelection] = useRowSelection();

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [activeStep]);

    const onRowClick = (record, rowIndex) => {
        setSelectedRowKeys(prevKeys => { // Use callback to access latest state
            if (prevKeys.includes(record.key)) {
                return [];
            } else {
                return [record.key];
            }
        });
    }

    const PersonCard = ({person}) => {
        return (
            <Space
                direction="vertical"
                size="small"
            >
                <Space><IconPersonCard/><span>{person.fullname}</span></Space>
                <Space><IconBookmark/><span>{person.position_title}</span></Space>
                <Space><IconGeoLocation/><span>{person.department}</span></Space>
            </Space>
        )
    }

    const handleRemove = (index) => {
        //get personnel uid
        const uid = personnel[index].uid;

        //Check if this uid belong to any detailSheet item
        ///If yes, return error message
        ///If no, remove personnel from store

        if (detailSheet.some(item => item.uid === uid)) {
            //Show error message
            notify('error', 'Không thể xoá, người này đã được thêm vào bảng kê chi phí')
            return
        }

        removePersonnel(index)
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'name',
            key: 'name',
            responsive: ['lg'],
            render: (text, record, index) => index + 1,
            align: 'center',
            width: '7%'
        },
        {
            title: 'Mã NV',
            dataIndex: 'uid',
            key: 'uid',
            width: breakpoints.lg ? '10%' : breakpoints.md ? '10%' : '20%'
        },
        {
            title: 'Họ tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text, record) => {
                if (!breakpoints.lg) {
                    return (
                        <Popover
                            content={<PersonCard person={record}/>}
                            trigger="click"
                            arrow={false}
                        >
                            <span className="text-primary"
                                  style={{cursor: 'pointer', whiteSpace: 'nowrap'}}>{record.fullname}</span>
                        </Popover>
                    )
                }
                return record.fullname
            }
        },
        {
            title: 'Phòng ban',
            dataIndex: 'department',
            key: 'department',
            responsive: ['md'],
        },
        {
            title: 'Vị trí',
            dataIndex: 'position_title',
            key: 'position_title',
            responsive: ['lg'],
        },
    ]

    const TableFooter = () => {
        return (
            <Flex
                justify="space-between"
                align="start"
                className="mt-2"
            >
                <div>Tổng cộng: {personnel.length} nhân viên</div>
                <Space>
                    <Button
                        icon={<DeleteOutlined />}
                        size="small"
                        type="text"
                        disabled={selectedRowKeys.length === 0}
                        className="me-3"
                        danger
                        onClick={() => handleRemove(personnel.findIndex(item => item.key === selectedRowKeys[0]))}
                    />
                    <Popover
                        content={<InputEmployeeIdSearch/>}
                        trigger="click"
                        placement="left"
                        arrow={false}
                    >
                        <Button
                            icon={<PlusOutlined/>}
                            size="small"
                            type="primary"
                        />
                    </Popover>
                </Space>
            </Flex>
        )
    }

    return (
        <Table
            columns={columns}
            dataSource={personnel}
            size="small"
            //style={{display: activeStep === thisStep ? 'block' : 'none'}}
            footer={() => <TableFooter/>}
            className="mb-4"
            rowKey="key"
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => onRowClick(record, rowIndex), // click row
                }
            }}
            pagination={{
                hideOnSinglePage: true,
                pageSize: 7
            }}
        />
    )
}

export default Step1Personnel;