import axios from 'axios';
import {notify} from "./toast-helper";
import {clearTokenAndRedirect, getAPIAppToken} from "./jwt-helper";
import {TEST} from "./config-helper";
export const API_URL = TEST ? 'https://localhost:3001' : 'https://app-service.forlife.vn/thanhtoan';
//The following setting is for TEST-ENVIROMENT
//export const API_URL = 'https://app-service.forlife.vn/thanhtoan-test';

axios.interceptors.request.use(function (config) {
    if (!config.url.startsWith('http')){
        config.baseURL = API_URL;
    }

    config.timeout = 15000;

    config.headers.set("Authorization", getAPIAppToken());

    return config;
}, function (error) {
    notify("error", error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if ((response?.status === 400) || (response?.status === 403)){
        notify("error", response.data.message);
        return null;
    }

    if (response?.data?.message) {
        const { error, message } = response?.data || {};

        if (error === 1) {
            notify("error", message);
            return null;
        }


        if (error === 0 || response.status === 200) {
            return message;
        }

        notify("error", "Lỗi dữ liệu trả về từ hệ thống");
    }

    return null;

}, function (error) {
    let errorMessage = '';
    errorMessage = error.message;
    notify("error", errorMessage);

    if (error?.response?.status === 401){
        clearTokenAndRedirect()
        return null;
    }

});


const api = axios;
export default api;
