import {useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {Button, Table} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";

const ExchangeRate = () => {
    const [rates, setRates] = useState([])
    const [updated, setUpdated] = useState('')

    const reloadRates = () => {
        api.get('/master/exchange/get').then((response) => {
            if (response){
                setRates(response.rates)
                setUpdated(response.updated)
            }
        })
    }

    const updateRates = () => {
        api.get('/master/exchange/update').then((response) => {
            if (response){
                notify('info', response);
                reloadRates();
            }
        })
    }

    useEffect(() => {
        reloadRates();
    }, []);

    const columns = [
        {
            title: 'Loại tiền tệ',
            dataIndex: 'currency_name',
            key: 'currency_name',
        },
        {
            title: 'Mã tiền',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Mua',
            dataIndex: 'buy',
            key: 'buy',
        },
        {
            title: 'Bán',
            dataIndex: 'sell',
            key: 'sell',
        },
        {
            title: 'Chuyển khoản',
            dataIndex: 'transfer',
            key: 'transfer',
        }
    ]

    return (
        <div>
            <div className="items-start-end mb-3">
                <div className="text-l text-bold">Bảng tỉ giá</div>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={updateRates}
                >
                    Cập nhật
                </Button>
            </div>
            <div className="text-muted mb-3">Cập nhật lúc: {updated}</div>

            <Table
                dataSource={rates}
                columns={columns}
            />
        </div>
    )
}
export default ExchangeRate