import {FormHeader} from "./FormHeader";
import {AutoComplete, Col, DatePicker, Form, Input, Row} from "antd";
import {useEffect, useState} from "react";
import {IconLanding, IconTakeOff} from "../../../../Icons";
import {useRequestPaymentLineStore, useTravelExpenseStore} from "../../../stores";

export const TravelPlan = ({setDays}) => {

    const [form] = Form.useForm();
    const [travelDays, setTravelDays] = useState('')
    const {getPlacesList} = useTravelExpenseStore()

    const {line, setData} = useRequestPaymentLineStore();

    const handleFormChange = () => {
        //console.log("Updating", form.getFieldsValue())
        setData(form.getFieldsValue());
    }

    const checkStartDate = (date) => {
        const doCheck = (date) => {
            if (!date) return
            //Check if date is valid and start date is before end date using dayjs
            const endDate = form.getFieldValue('to_date')
            //if endDate is not null, check if start date is before end date otherwise do nothing
            if (!endDate) return

            //if start date is after end date, set end date to start date
            if (date.isAfter(endDate)) {
                form.setFieldsValue({to_date: date})
            }
        }

        doCheck(date);
        handleFormChange()
        calcDays()
    }

    const checkEndDate = (date) => {
        const doCheck = (date) => {
            if (!date) return
            //Check if date is valid and end date is after start date using dayjs

            const startDate = form.getFieldValue('from_date')

            //if startDate is not null, check if start date is before end date otherwise do nothing
            if (!startDate) return

            //if start date is after end date, set end date to start date
            if (date.isBefore(startDate)) {
                form.setFieldsValue({from_date: date})
            }
        }

        doCheck(date)
        handleFormChange()
        calcDays()
    }

    const calcDays = () => {
        //Calculate days between start and end date, return null if either date is null
        const startDate = form.getFieldValue('from_date')
        const endDate = form.getFieldValue('to_date')
        if (!startDate || !endDate) {
            setTravelDays('')
            setDays(null)
            return
        }

        const days = endDate.diff(startDate, 'days')
        setTravelDays(`(${days === 0 ? 1 : days} ngày)`);
        setDays(days === 0 ? 1 : days)
        //console.log("Travel days", days)
    }

    const checkDates = () => {
        checkStartDate(form.getFieldValue('from_date'))
        checkEndDate(form.getFieldValue('to_date'))
    }

    useEffect(() => {
        if (line.length === 0){
            form.resetFields();
        } else {
            form.setFieldsValue(line);
            //handleFormChange()
            calcDays()
        }
    }, [line])

    return (
        <div>
            <FormHeader/>
            <Form
                form={form}
                layout="vertical"
            >
                <div className="mb-2">Thời gian công tác {travelDays}</div>

                <Row>
                    <Col span={9}>
                        <Form.Item
                            name="from_date"
                            className="narrow-form-item"
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                placeholder="Từ ngày"
                                format="DD/MM/YYYY"
                                onChange={checkDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div className="w-100 text-center align-items-baseline p-2">
                            Đến
                        </div>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="to_date"
                            className="narrow-form-item"
                        >
                            <DatePicker
                                placeholder="Đến ngày"
                                style={{width: '100%'}}
                                format="DD/MM/YYYY"
                                onChange={checkDates}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="departure"
                    label="Điểm đi"
                >
                    <AutoComplete
                        options={
                            //Combine all departure and destination from detailSheet array
                            //[...new Set([...new Set(detailSheet.map(item => item?.departure)), ...new Set(detailSheet.map(item => item?.destination))].filter(item => item))].map(item => ({value: item}))
                            getPlacesList()
                        }
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onBlur={handleFormChange}
                    >
                        <Input
                            prefix={<IconTakeOff/>}
                            placeholder="Nhập điểm đi"
                            allowClear
                        />
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    name="destination"
                    label="Điểm đến"
                >
                    <AutoComplete
                        options={
                            //Combine all departure and destination from detailSheet array
                            //[...new Set([...new Set(detailSheet.map(item => item?.departure)), ...new Set(detailSheet.map(item => item?.destination))].filter(item => item))].map(item => ({value: item}))
                            getPlacesList()
                        }
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onBlur={handleFormChange}
                    >
                        <Input
                            prefix={<IconLanding/>}
                            placeholder="Nhập điểm đến"
                            allowClear
                        />
                    </AutoComplete>
                </Form.Item>
            </Form>
        </div>
    )
}
