import {Col, Form, Input, Row, Space, Radio} from "antd";
import {
    IconBus,
    IconCarRental,
    IconGeoLocation,
    IconGlobal,
    IconMotorbike,
    IconRoad, IconTotalExpense,
} from "../../../../Icons";

import {processFormValues, useRequestPaymentLineStore} from "../../../stores";
import {formatNumber} from "../../../../../Helpers/number-helper";
import {useEffect} from "react";

export const TransportationExpense = ({}) => {
    const [form] = Form.useForm();

    const iconSize = {
        width: 18,
        height: 18
    }

    const radioButtonClick = (e) => {

        const currentValue = form.getFieldValue('local_transportation_type');

        if (currentValue === e.target.value) {
            form.setFieldValue('local_transportation_type', null)
        } else {
            form.setFieldValue('local_transportation_type', e.target.value)
        }
    }

    const {line, setData} = useRequestPaymentLineStore();

    const calcFields = ['transportation_cost', 'foreign_cost', 'personal_fuel_cost', 'personal_depreciation_cost', 'local_transportation']

    const handleChanges = () => {
        const currentData = processFormValues(form)

        //If any of the fields needed to calculate transportation_total is empty, assign 0 to it
        calcFields.forEach(field => {
            if (!currentData[field]) {
                currentData[field] = 0
            } else {
                //remove commas from the value and return it as a number
                if (typeof currentData[field] === 'string')
                    currentData[field] = Number(currentData[field]?.replace(/,/g, ''))
            }
        })

        const transportation_total = currentData.transportation_cost + currentData.foreign_cost + currentData.personal_fuel_cost + currentData.personal_depreciation_cost + currentData.local_transportation
        currentData.transportation_total = transportation_total


        //Below code is not needed, but just an expriement to see if it works, because the above code is not really working (processFormValues not formatting numbers)

        //Format all form fields in calcFields in number format with thousand separator, update all in one to prevent multiple re-renders
        //Get all form values
        /*
        const formValues = form.getFieldsValue()

        //Format all fields in calcFields
        calcFields.forEach(field => {
            formValues[field] = 0//formatNumber(formValues[field])
        })

        formValues['transportation_total'] = 0//formatNumber(transportation_total)

        form.setFieldsValue(formValues)

         */
        //END


        form.setFieldValue('transportation_total', formatNumber(transportation_total))
        setData(form.getFieldsValue());

    }

    useEffect(() => {
        if (line.length === 0){
            form.resetFields();
        } else {
            //form.setFieldsValue(line)
        }
    }, [line])

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Row gutter={4}>
                <Col span={12}>
                    <Form.Item
                        name="transportation_cost"
                        label={
                            <Space>
                                <IconRoad style={{...iconSize}}/>
                                <span>Vé tàu xe</span>
                            </Space>
                        }
                        className="narrow-form-item"
                    >
                        <Input
                            style={{width: '100%'}}
                            allowClear
                            addonAfter="VND"
                            onPressEnter={handleChanges}
                            onBlur={handleChanges}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={<Space>
                            <IconGlobal style={{...iconSize}}/>
                            <span>Chi phí nước ngoài</span>
                        </Space>}
                        name="foreign_cost"
                        className="narrow-form-item"
                    >
                        <Input
                            style={{width: '100%'}}
                            allowClear
                            addonAfter="VND"
                            onPressEnter={handleChanges}
                            onBlur={handleChanges}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Space>
                <IconMotorbike style={{...iconSize}}/>
                <span>Chi phí sử dụng xe cá nhân</span>
            </Space>
            <Row gutter={4}>
                <Col span={12}>
                    <Form.Item
                        name="personal_fuel_cost"
                        label="Xăng xe"
                        className="narrow-form-item"
                    >
                        <Input
                            style={{width: '100%'}}
                            allowClear
                            addonAfter="VND"
                            onPressEnter={handleChanges}
                            onBlur={handleChanges}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="personal_depreciation_cost"
                        label="Phí khấu hao xe"
                        className="narrow-form-item"
                    >
                        <Input
                            style={{width: '100%'}}
                            allowClear
                            addonAfter="VND"
                            onPressEnter={handleChanges}
                            onBlur={handleChanges}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Space>
                <IconGeoLocation style={{...iconSize}}/>
                <span>Di chuyển nội vùng</span>
            </Space>
            <Form.Item
                name="local_transportation_type"
                className="narrow-form-item"
                initialValue={null}
            >
                <Radio.Group
                    buttonStyle="solid"
                    className="equal-width-radio-group"
                    name="localTransportationType"
                >
                    <Radio.Button
                        value="bus"
                        onClick={radioButtonClick}
                    >
                        <Space>
                            <IconBus/>
                            <span>Xe bus</span>
                        </Space>
                    </Radio.Button>
                    <Radio.Button
                        value="grab"
                        onClick={radioButtonClick}
                    >
                        <Space>
                            <IconMotorbike/>
                            <span>Xe ôm</span>
                        </Space>
                    </Radio.Button>
                    <Radio.Button
                        value="rental"
                        onClick={radioButtonClick}
                    >
                        <Space>
                            <IconCarRental/>
                            <span>Thuê xe</span>
                        </Space>
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="local_transportation"
                className="narrow-form-item"
            >
                <Input
                    style={{width: '100%'}}
                    allowClear
                    addonAfter="VND"
                    onPressEnter={handleChanges}
                    onBlur={handleChanges}
                />
            </Form.Item>

            <Form.Item
                name="transportation_total"
                className="text-red text-bold"
                style={{
                    color: 'red',
                    fontWeight: 'bold'

                }}
            >
                <Input
                    status="error"
                    prefix={<IconTotalExpense/>}
                    addonBefore="Tổng cộng"
                    readOnly

                />
            </Form.Item>
        </Form>
    )
}
