import {Badge, Button, DatePicker, Form, Input, Modal, Space, Tabs, Tooltip} from "antd";
import {
    AppstoreAddOutlined, CloudDownloadOutlined,
    FilterOutlined,
    FolderOpenOutlined,
    FormOutlined,
    OrderedListOutlined,
    ReloadOutlined, TableOutlined
} from "@ant-design/icons";
import PaymentRequestForm from "./PaymentRequestForm";
import {useEffect, useState} from "react";
import RequestList from "./RequestList";
import {useNavigate, useParams} from "react-router-dom";
import RequestFilter from "./RequestFilter";
import Search from "antd/es/input/Search";
import {UseSiteConfig} from "../../Helpers/config-helper";
import RequestListAdmin from "./RequestListAdmin";
import {formatNumber, readNumber} from "../../Helpers/number-helper";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";
import {useUserInfo} from "../../Helpers/user-session-helper";
import {REQUEST_STATUS, ROLES} from "./const";
import RequestView from "./RequestView";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import TableColumnFilter from "../TableColumnFilter";

const PaymentRequest = () => {
    //get paramstring

    const {tab, reload} = useParams();
    const tabKeys = ['1', '2'];
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [inputOpen, setInputOpen] = useState(false);
    const [needReload, setNeedReload] = useState(false);
    const [needAdminReload, setNeedAdminReload] = useState(false);
    const [editRequest, setEditRequest] = useState(null);

    const [reloadDisabled, setReloadDisabled] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState('1');

    const [filterOpen, setFilterOpen] = useState(false);
    const {siteConfig} = UseSiteConfig()

    const [filterCount, setFilterCount] = useState(0);
    const [filter, setFilter] = useState({});
    const [userSearch, setUserSearch] = useState(null);

    const [bulkAction, setBulkAction] = useState(false);

    const [bulkModalOpen, setBulkModalOpen] = useState(false);
    const [bulkData, setBulkData] = useState([]);

    const [loading, setLoading] = useState(false)

    const [requestViewOpen, setRequestViewOpen] = useState(false);
    const [requestViewData, setRequestViewData] = useState(null);

    const [downloadTrigger, setDownloadTrigger] = useState(false);

    const userInfo = useUserInfo();
    const setPageLoading = UseLoading((state) => state.setLoading);

    const [tableCustomizeOpen, setTableCustomizeOpen] = useState(false);

    useEffect(() => {
        if (tab && tabKeys.includes(tab)) {
            setActiveTabKey(tab);
        }

        return () => {
            setActiveTabKey('1')
        }


    }, [tab, reload]);

    useEffect(() => {
        if (!bulkModalOpen){
            form.resetFields();
            setLoading(false)
        }
    }, [bulkModalOpen]);
    const openEditForm = (request) => {
        //check if request_status is new or return_to_requester

        if ([REQUEST_STATUS.new.code, REQUEST_STATUS.return_to_requester.code].includes(request.request_status)) {
            setEditRequest(request);
            setInputOpen(true);
        } else {
            //get request data from api

            setPageLoading(true);
            api.post('/request/user/view', {_id: request._id}).then((response) => {
                if (response){
                    setRequestViewData(response)
                    setRequestViewOpen(true);
                }

            }).finally(() => {
                setPageLoading(false)
            })
        }
    }

    const openFormInput = () => {
        setInputOpen(true);
    }

    const closeFormInput = () => {
        setInputOpen(false);
        setEditRequest(null)
    }

    const adminTab = {
        key: '2',
        label: <span><FolderOpenOutlined/> Cần xử lý</span>,
        children: <RequestListAdmin
            tabKey="admin_request"
            reload={needAdminReload}
            filter={filter}
            setBulkAction={setBulkAction} setBulkData={setBulkData}
            downloadTrigger={downloadTrigger}
            colFilterOpen={tableCustomizeOpen}
            setColFilterOpen={setTableCustomizeOpen}
        />,
    }

    const tabItems = [
        {
            key: '1',
            label: <span><OrderedListOutlined/> Đề nghị của tôi</span>,
            children: <RequestList tabKey="my_request" reload={needReload} search={userSearch} onEdit={openEditForm}/>
        }]

    //if user is admin, add admin tab
    if (userInfo?.role && ROLES.includes(userInfo?.role)){
        tabItems.push(adminTab);
    }

    const deboundReload = () => {
        //get currrent admin pagination
        const adminPagination = JSON.parse(window.localStorage.getItem('adminPagination'));
        if (adminPagination)
            window.localStorage.setItem('adminPagination', JSON.stringify({...adminPagination, currentPage: 1}))

        if (tab === '1')
            setNeedReload(!needReload);
        else
            setNeedAdminReload(!needAdminReload);
        //reload immediately, but set the button to disabled, to prevent user click too fast and then re-enable reload button after 3 seconds
        setReloadDisabled(true);
        setTimeout(() => {
            setReloadDisabled(false);
        }, 3000);
    }

    const handleFilterChange = () => {
        setNeedAdminReload(!needAdminReload)
        setFilterOpen(false);
    }

    const handleBulkAction = () => {
        //validate form before submit
        form.validateFields().then((values) => {
            //filter out null value

            values = Object.entries(values).reduce((acc, [key, value]) => {
                if (value !== null && value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            //get only _id list from bulkData
            const _idList = bulkData.map((item) => item._id);

            //send request to server

            setLoading(true);
            api.post('/request/process/bulk', {_ids: _idList, ...values}).then((response) => {
                if (response) {
                    notify('success', response);
                    setBulkModalOpen(false);
                    setNeedAdminReload(!needAdminReload);
                }
            }).finally(() => {
                setLoading(false);
            });
        }).catch((err) => {

        })
    }

    return (
        <div>
            <div className="items-start-end">
                <h1>Danh sách đề nghị thanh toán</h1>
                <Button type="primary" onClick={openFormInput} icon={<FormOutlined/>}> Tạo đề nghị</Button>
            </div>
            <Tabs
                //add debound (3 seconds) to this button, to prevent user click too fast
                tabBarExtraContent={
                    <div>
                        <Space>
                            <Button
                                style={{display: activeTabKey === '1' ? 'none' : 'block'}}
                                icon={<CloudDownloadOutlined />}
                                onClick={() => {
                                    //use xlsx to export data to excel
                                    setDownloadTrigger(!downloadTrigger)
                                }}
                            >Tải về Excel</Button>
                            <Search
                                placeholder="Tìm kiếm"
                                allowClear
                                onSearch={(value, event, options) => {
                                    const searchValue = (value.trim() === "") ? (options.source === "clear") ? "" : null : value.trim();

                                    if (tab === '1' || !tab) {
                                        setUserSearch(searchValue);
                                        return;
                                    }

                                    let newFilter = {}
                                    if (searchValue !== '') {
                                        //add search criteria to filter
                                        newFilter = {
                                            ...filter,
                                            search: searchValue
                                        }
                                    } else {
                                        //remove search criteria from filter
                                        newFilter = {
                                            ...filter
                                        }
                                        delete newFilter.search;
                                    }


                                    window.localStorage.setItem('request_filter', JSON.stringify(newFilter));
                                    window.localStorage.setItem('adminPagination', JSON.stringify({currentPage: 1, pageSize: 10}))

                                    setFilter(newFilter);

                                    //if (searchValue !== '') {
                                        setNeedAdminReload(!needAdminReload)
                                    //}
                                }}
                            />
                            <Button
                                disabled={reloadDisabled}
                                title="Làm mới dữ liệu"
                                icon={<ReloadOutlined/>}
                                onClick={deboundReload}>Làm mới dữ liệu
                            </Button>
                            <Badge count={filterCount} style={{display: activeTabKey === '1' ? 'none' : 'block'}}
                            >
                                <Button
                                    style={{display: activeTabKey === '1' ? 'none' : 'block'}}
                                    title="Lọc dữ liệu"
                                    onClick={setFilterOpen}
                                    icon={<FilterOutlined/>}
                                >
                                    Lọc
                                </Button>
                            </Badge>
                            <Button
                                style={{display: activeTabKey === '1' ? 'none' : 'block'}}
                                icon={<TableOutlined />}
                                title="Cá nhân hóa hiển thị"
                                onClick={() => setTableCustomizeOpen(true)}
                            />
                            <Tooltip title="Duyệt theo lô">
                            <Button
                                danger
                                icon={<AppstoreAddOutlined/>}
                                disabled={bulkAction === false}
                                onClick={setBulkModalOpen}
                                style={{display: activeTabKey === '1' ? 'none' : 'block'}}
                            />
                            </Tooltip>
                        </Space>
                    </div>
                }
                items={tabItems}
                activeKey={activeTabKey}
                onChange={(key) => {
                    navigate(`/request/${key}`);
                }}
            />
            <RequestView open={requestViewOpen}
                         onClose={() => {
                             setRequestViewData(null)
                             setRequestViewOpen(false)
                         }}
                         requestData={requestViewData}
            />
            <PaymentRequestForm open={inputOpen}
                                onClose={closeFormInput}
                                onAdded={() => setNeedReload(!needReload)}
                                editData={editRequest}
            />
            <RequestFilter
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
                onOk={handleFilterChange}
                config={siteConfig}
                onFilterChange={(filter) => {
                    //check if filter has value, count the object's length and setFilterCount after filter out any props with null or "Tất cả" value
                    //filter out any props with null or "Tất cả" value
                    let filterCount = 0;
                    Object.keys(filter).forEach((key) => {
                        if (filter[key] !== null && filter[key] !== "Tất cả" && (typeof filter[key] !== 'undefined') && filter[key] !== '0') {
                            filterCount++
                        }
                    });
                    setFilterCount(filterCount);
                    setFilter(filter);
                }}
            />
            <Modal
                open={bulkModalOpen}
                width={600}
                centered
                onCancel={() => setBulkModalOpen(false)}
                onOk={handleBulkAction}
                title="Xác nhận cấp nguồn"
                confirmLoading={loading}
            >
                <Form
                    className="mt-4"
                    form={form}
                    labelCol={{
                        flex: '150px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                >
                    <Form.Item
                        label="Tổng tiền cần chi:"
                    >
                        <span
                            className="text-danger text-l text-bold">{formatNumber(bulkData.reduce((total, item) => total + item.approved_foreign_to_vnd, 0))} </span>
                        <span className="text-bold">VNĐ</span>
                        <br/>
                        <span
                            className="text-success text-bold">{readNumber(bulkData.reduce((total, item) => total + item.approved_foreign_to_vnd, 0))}</span>
                    </Form.Item>
                    <Form.Item
                        label="Ngày duyệt UNC"
                        name="payment_date"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn ngày Tài chính xác nhận'
                            }]}
                    >
                        <DatePicker
                            style={{width: '100%'}}
                            format="DD-MM-YYYY"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nguồn chi"
                        name="payment_source"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn nhập thông tin nguồn chi'
                            }]}
                    >
                        <Input
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default PaymentRequest