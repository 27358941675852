import {useCallback, useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {REQUEST_STATUS} from "./const";
import {Table, Tag, Tooltip, Popover, Space, Button} from "antd";
import {formatNumber, readNumber} from "../../Helpers/number-helper";
import {useNavigate} from "react-router-dom";
import {IconCurrencyExchange} from "../Icons";
import {CopyOutlined} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {utils, writeFileXLSX} from 'xlsx';
import {ExpandableText, processTableColumns} from "../../Helpers/common-helper";
import TableColumnFilter from "../TableColumnFilter";
import useKeypress from "./useKeypress";
import QRScanModal from "../QRScanModal";

import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');
 */

const currenciesColors =
    {
        VND: '#0074D9',
        USD: '#2ECC40',
        CNY: '#6DDC00',
        JPY: '#FF851B',
        EUR: '#7FDBFF',
        HKD: '#FF4136',
        THB: '#39CCCC',
        SGD: '#B10DC9',
        KRW: '#FFD700',
        RUB: '#001F3F',
        AUD: '#F012BE',
        CAD: '#3D9970'
    };


const RequestListAdmin = ({
                              tabKey,
                              reload,
                              onEdit,
                              filter,
                              setBulkAction,
                              setBulkData,
                              downloadTrigger,
                              colFilterOpen,
                              setColFilterOpen
                          }) => {
    const navigate = useNavigate();

    const [localReload, setLocalReload] = useState(false);
    const [isTableRendering, setIsTableRendering] = useState(false);

    const tabList = {
        my_request: {
            endPoint: '/request/list'
        },
        admin_request: {
            endPoint: '/request/admin/list'
        }
    }

    const editRequest = (request) => {
        if (tabKey !== 'my_request') {
            navigate(`/admin/request/${request._id}`)
        } else {
            onEdit(request);
        }
    }

    const columns = [
        {
            title: 'Mã đề nghị',
            label: 'Mã đề nghị',
            dataIndex: 'request_code',
            hidden: false,
            key: 'request_code',
            render: (text, record) => {
                return (
                    <Popover
                        content={(
                            <Space>
                                <Button title="Sao chép mã đề nghị"
                                        className="rounded-2"
                                        size="small"
                                        icon={<CopyOutlined/>}
                                        onClick={async () => {
                                            await navigator.clipboard.writeText(record.request_code);
                                            notify('info', 'Đã sao chép vào clipboard', 2);
                                        }}
                                />
                            </Space>
                        )}
                        arrow={false}
                    >
                        <span className="link-text" onClick={() => editRequest(record)}>{record.request_code}</span>
                    </Popover>
                )
            }
        },
        {
            title: 'Người đề nghị',
            label: 'Người đề nghị',
            dataIndex: 'created_by_name',
            key: 'created_by_name',
            hidden: false,
        },
        {
            title: 'Phòng ban đề nghị',
            label: 'Phòng ban đề nghị',
            dataIndex: 'request_department',
            key: 'created_department',
            hidden: false,
        },
        {
            title: 'Loại chi phí',
            label: 'Loại chi phí',
            dataIndex: 'expense_type',
            hidden: false,
        },
        {
            title: 'Mã/PO',
            label: 'Mã LSX/DPO/IPO',
            dataIndex: 'special_code',
            hidden: false,
            render: (text, record) => <ExpandableText text={record.special_code}/>
        },
        {
            title: 'PO(ERP)',
            label: 'PO(ERP)',
            dataIndex: 'erp_code',
            key: 'erp_code',
        },
        {
            title: 'Mùa vụ',
            label: 'Mùa vụ',
            dataIndex: 'special_season',
            hidden: false,
        },
        {
            title: 'Ghi chú',
            label: 'Ghi chú',
            dataIndex: 'approval_note',
            render: (text, record) => <ExpandableText text={record.approval_note}/>,
            hidden: false,
        },
        {
            title: 'Diễn giải',
            label: 'Diễn giải',
            dataIndex: 'description',
            render: (text, record) => <ExpandableText text={record.description}/>,
            hidden: false,
        },
        {
            title: 'Phân loại',
            label: 'Phân loại',
            dataIndex: 'instruction',
            render: (text, record) => <ExpandableText text={record.instruction}/>,
            hidden: false,
        },
        {
            title: 'Thụ hưởng',
            label: 'Tên NCC/Người thụ hưởng',
            dataIndex: 'receiver_name',
            hidden: false,
            render: (text, record) => <ExpandableText text={record.receiver_name} maxLength={15}/>
        },
        {
            title: 'Loại tiền',
            label: 'Loại tiền',
            dataIndex: 'currency_code',
            hidden: false,
            align: 'center',
            render: (text, record) => (
                <span>{record.currency_code ?
                    <Tag color={currenciesColors[record.currency_code]}>{record.currency_code}</Tag> : 'VND'}</span>
            )
        },
        {
            title: 'Đề nghị chi',
            label: 'Số tiền đề nghị chi',
            dataIndex: 'request_amount',
            hidden: false,
            key: 'request_amount',
            align: 'right',
            /*
            render: (text, record) => (
                <span>{formatNumber(record.request_amount)}</span>
            )
             */
            render: (text, record) => {
                if (record.currency_code !== 'VND') {
                    //if the currency is not 'VND', apply two decimal places if the value does not have decimal places
                    //else display normally
                    return (
                        <span>{formatNumber(record.request_amount, 2)}</span>
                    )
                } else {
                    return (
                        <span>{formatNumber(record.request_amount)}</span>
                    )
                }
            }
        },
        {
            title: 'Duyệt VND',
            label: 'Số tiền duyệt VND',
            dataIndex: 'approved_foreign_to_vnd',
            hidden: false,
            align: 'right',
            render: (text, record) => {
                //check if the currency is 'VND' or not
                if (record.currency_code === 'VND') {
                    //currency is VND, compare the request_amount and approved_foreign_to_vnd
                    //if different, format the text with text-warning, else display normally

                    if (record.request_amount !== record.approved_foreign_to_vnd) {
                        return (
                            <span className="text-warning">{formatNumber(record.approved_foreign_to_vnd)}</span>
                        )
                    }
                }

                return (
                    <span>{formatNumber(record.approved_foreign_to_vnd)}</span>
                )
            }
        },
        {
            title: 'Đã cấp',
            label: 'Số tiền đã cấp',
            dataIndex: 'payments',
            hidden: false,
            export_rule: (row) => row.payments.reduce((total, payment) => total + payment.vnd_amount, 0),
            align: 'right',
            render: (text, record) => {
                //get total amount of payments using reduce
                const totalAmount = record?.payments.reduce((total, payment) => total + payment.vnd_amount, 0);
                return (
                    <span>{totalAmount ? formatNumber(totalAmount) : "-"}</span>
                )
            }
        },
        {
            title: 'Còn lại',
            label: 'Số tiền còn lại',
            dataIndex: 'remaining_amount',
            hidden: false,
            export_rule: (row) => row?.payments.length > 0 ? (row.approved_foreign_to_vnd - row?.payments.reduce((total, payment) => total + payment.vnd_amount, 0)) : '-',
            align: 'right',
            render: (text, record) => {
                //get total amount of payments using reduce
                const totalAmount = record?.payments.reduce((total, payment) => total + payment.vnd_amount, 0);

                const remainingAmount = record?.payments.length > 0 ? (record.approved_foreign_to_vnd - totalAmount) : "-";

                //console.log(record.request_code, remainingAmount, totalAmount, record.approved_foreign_to_vnd);
                //if remainingAmount is greater than 0, format the text with text-warning, else display normally
                //if remainingAmount is NaN, display '-'

                const color = remainingAmount > 0 ? 'text-warning' : '';
                return (
                    <span className={color}>{isNaN(remainingAmount) ? "-" : formatNumber(remainingAmount)}</span>
                )
            }
        },
        {
            title: 'Y/c chi',
            label: 'Ngày yêu cầu chi',
            dataIndex: 'request_payment_date',
            hidden: false,
            export_rule: (row) => row.request_payment_date ? dayjs(row.request_payment_date).format('DD-MM-YYYY') : '-',
            align: 'center',
            render: (text, record) => {
                return record.request_payment_date ?
                    <span>{dayjs(record.request_payment_date).format('DD-MM-YYYY')}</span>
                    : "-"
            }
        },
        {
            title: 'Duyệt chi',
            label: 'Ngày duyệt chi',
            dataIndex: 'approved_request_payment_date',
            hidden: false,
            export_rule: (row) => row.approved_request_payment_date ? dayjs(row.approved_request_payment_date).format('DD-MM-YYYY') : '-',
            align: 'center',
            render: (text, record) => {
                return record.approved_request_payment_date ?
                    <span>{dayjs(record.approved_request_payment_date).format('DD-MM-YYYY')}</span>
                    : "-"
            }
        },
        {
            title: 'TK',
            label: 'Tài khoản đi tiền',
            dataIndex: 'transfer_from_account',
            hidden: false,
        },
        {
            title: 'Tình trạng',
            label: 'Tình trạng yêu cầu',
            dataIndex: 'request_status',
            hidden: false,
            export_rule: (row) => REQUEST_STATUS[row.request_status].label,
            align: 'center',
            render: (text, record) => {
                let {label, color} = REQUEST_STATUS[record.request_status];
                return (
                    <Tag color={color}>{label}</Tag>
                )
            }
        },
        {
            title: 'Ghi chú',
            label: 'Ghi chú',
            dataIndex: 'approval_note',
            hidden: true,
            //export_rule: (row) => REQUEST_STATUS[row.request_status].label,
            align: 'left',
        },
        {
            title: (<span>Người xử lý</span>),
            label: 'Người xử lý đề nghị',
            dataIndex: 'accountant_id',
            hidden: false,
            export_rule: (row) => row?.accountant_info?.accountant_fullname,
            align: 'center',
            tabKey: 'my-request',
            render: (text, record) => {
                if (tabKey === 'admin_request') {
                    return (
                        <Tag
                            color={record?.accountant_info?.tagColor}>{record?.accountant_info?.accountant_fullname}</Tag>
                    )
                }
            }
        },
        {
            title: 'Ngày tạo YC',
            label: 'Ngày tạo yêu cầu',
            dataIndex: 'created_at',
            hidden: false,
            export_rule: (row) => dayjs(row.created_at).format('DD-MM-YYYY'),
            align: 'center',
            render: (text, record) => <span>{dayjs(record.created_at).format('DD-MM-YYYY')}</span>
        },
        {
            title: 'Ngày tiếp nhận',
            label: 'Ngày tiếp nhận yêu cầu',
            dataIndex: 'request_received_at',
            hidden: false,
            export_rule: (row) => row.request_received_at ? dayjs(row.request_received_at).format('DD-MM-YYYY') : '-',
            align: 'center',
            //if the request_received_at is null, display '-'
            render: (text, record) =>
                <span>{record.request_received_at ? dayjs(record.request_received_at).format('DD-MM-YYYY') : "-"}</span>
        }
    ];

    const changeView = () => {
        processTableColumns('request_admin_column', 'request_admin_column_order', columns, setTableColumns);
    }
    const onColumnViewChange = () => {
        changeView()
    }

    //const setLoading = UseLoading(state => state.setLoading);
    const [dataList, setDataList] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({currentPage: 1, pageSize: 10});

    const [tableColumns, setTableColumns] = useState([]);

    const [scannerModalVisible, setScannerModalVisible] = useState(false);

    useKeypress('F1', () => {
        setScannerModalVisible(true)
    });

    useEffect(() => {
        changeView();
        // eslint-disable-next-line react-hooks/rules-of-hooks

    }, []);

    useEffect(() => {
        if (selectedRowKeys.length > 0)
            setBulkAction(true);
        else
            setBulkAction(false);

        //setBulkData by filter dataList with index match item's array index in selectedRowKeys
        const selectedDataList = selectedRowKeys.map((row) => dataList?.documents[row]);
        setBulkData(selectedDataList);

    }, [selectedRowKeys]);

    const exportFile = useCallback(() => {
        const excelData = dataList.documents.map((row, index) => (
            tableColumns.filter((column) => !column.hidden).reduce((obj, column) => {
                let value = row[column.dataIndex];

                if (column.export_rule) {
                    value = column.export_rule(row);
                }

                obj[column.label] = value;
                return obj;
            }, {STT: index + 1})
        ));

        const ws = utils.json_to_sheet(excelData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");

        //Generate file name based on date
        const date = new Date();
        const fileName = `Danh sách đề nghị ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`;
        writeFileXLSX(wb, fileName);

        notify('success', 'Đã tải xuống file: ' + fileName, 3);
    }, [dataList.documents, tableColumns]);

    useEffect(() => {
        if (dataList.documents && dataList.documents.length > 0) {
            exportFile();
        }
    }, [downloadTrigger]);

    const fetchData = (currentPage = 1, pageSize = 10, bypassLocalPagination = false) => {
        const tab = tabList[tabKey];
        if (tab) {
            setIsTableRendering(true)

            //check localStorage if have filter
            let request_filter = null;
            try {
                request_filter = JSON.parse(localStorage.getItem('request_filter'));
            } catch (err) {
            }

            //check if localStorage has pagination key
            let localPagination = null;
            try {
                localPagination = JSON.parse(localStorage.getItem('adminPagination'));
            } catch (err) {
            }

            if (localPagination && !bypassLocalPagination) {
                setPagination(localPagination);

                currentPage = localPagination.currentPage;
                pageSize = localPagination.pageSize;
            }

            //api.post(tab.endPoint, {currentPage, pageSize, filter: request_filter || filter}).then((response) => {
            api.post(tab.endPoint, {
                currentPage: currentPage, //pagination.currentPage,
                pageSize: pageSize, //pagination.pageSize,
                filter: request_filter || filter
            }).then((response) => {
                if (response) {
                    setSelectedRowKeys([])
                    setDataList(response);
                }
            }).finally(() => {
                setIsTableRendering(false)
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, [tabKey, reload, localReload]);


    const isRowSelectable = (record) => {
        return record?.request_status === 'waiting_for_process';
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: !isRowSelectable(record),
        }),
    };

    return (
        <div>
            <Table
                loading={isTableRendering}
                rowSelection={rowSelection}
                dataSource={dataList?.documents}
                columns={tableColumns.filter((column) => !column.hidden)}//{columns.filter((column) => column.tabKey !== tabKey)}
                scroll={{
                    x: 'max-content',
                    y: 'calc(100vh - 300px)',
                    scrollToFirstRowOnChange: true
                }}
                pagination={{
                    onChange: ((page, pageSize) => {
                        setSelectedRowKeys([]);
                        setPagination({currentPage: page, pageSize: pageSize})
                        setLocalReload(!localReload);
                        window.localStorage.setItem('adminPagination', JSON.stringify({
                            currentPage: page,
                            pageSize: pageSize
                        }))
                    }),
                    total: dataList?.totalItems,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} dòng`,
                    pageSize: pagination.pageSize,
                    current: pagination.currentPage
                }}
                summary={(pageData) => {
                    let totalRequested = 0;
                    let totalApproved = 0;
                    let isMultipleCurrency = false;
                    let lastCurrencyCode = null;

                    //Also need to check if the currency is the same
                    pageData.forEach(({request_amount, approved_foreign_to_vnd, currency_code}) => {
                        //only do the calculation if the values is greater than 0
                        if (typeof request_amount !== 'undefined' && request_amount !== null && request_amount > 0)
                            totalRequested += request_amount;

                        if (typeof approved_foreign_to_vnd !== 'undefined' && approved_foreign_to_vnd !== null && approved_foreign_to_vnd > 0)
                            totalApproved += approved_foreign_to_vnd;

                        //check if the currency is the same
                        if (lastCurrencyCode === null)
                            lastCurrencyCode = currency_code;
                        else if (lastCurrencyCode !== currency_code)
                            isMultipleCurrency = true;
                    });
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="bg-light">
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <span className="text-bold">Tổng tiền cần chi: {formatNumber(totalRequested)}</span>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0} align="left">
                                    <span className="text-bold text-blinking text-danger">{isMultipleCurrency ? <Tooltip
                                        title="Danh sách này có nhiều hơn 1 loại tiền tệ. Có thể ảnh hưởng đến độ chính xác của cột 'Đề nghị chi'"><IconCurrencyExchange/></Tooltip> : null}</span>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={3} align="left">
                                    <Tooltip title={readNumber(totalApproved)}>
                                        <span
                                            className="text-bold text-success">Đã duyệt: {formatNumber(totalApproved)}</span>
                                    </Tooltip>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={columns.length - 5}/>
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
            />
            <TableColumnFilter open={colFilterOpen} onClose={() => setColFilterOpen(false)} columns={columns}
                               onColumnChange={onColumnViewChange}
                               keys={{view: 'request_admin_column', order: 'request_admin_column_order'}}/>
            <QRScanModal open={scannerModalVisible} onClose={() => setScannerModalVisible(false)}/>
        </div>
    )
}

export default RequestListAdmin