import {Badge, Button, Dropdown, Space, Table, Tag} from "antd";
import {useCallback, useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {formatNumber} from "../../Helpers/number-helper";
import {
    AppstoreAddOutlined,
    CloudDownloadOutlined,
    DollarOutlined, EyeOutlined, FileSyncOutlined,
    FilterOutlined, TableOutlined
} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import PaymentListFilter from "./PaymentListFilter";
import {useNavigate} from "react-router-dom";
import PaymentConfirm from "./PaymentConfirm";
import BulkPaymentConfirm from "./BulkPaymentConfirm";
import {utils, writeFileXLSX} from "xlsx";
import {notify} from "../../Helpers/toast-helper";
import PaymentView from "./PaymentView";
import {ExpandableText, processTableColumns} from "../../Helpers/common-helper";
import {UseSiteConfig} from "../../Helpers/config-helper";
import TableColumnFilter from "../TableColumnFilter";

import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */


const PaymentList = () => {
    const navigate = useNavigate();

    const [payList, setPayList] = useState([]);
    //const setLoading = UseLoading(state => state.setLoading);
    const [tableLoading, setTableLoading] = useState(false);

    const [filter, setFilter] = useState({});
    const [filerOpen, setFilterOpen] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmData, setConfirmData] = useState(null);

    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [bulkOpen, setBulkOpen] = useState(false);

    const [colFilterOpen, setColFilterOpen] = useState(false);
    const [tableColumns, setTableColumns] = useState([]);

    const siteConfig = UseSiteConfig(state => state.siteConfig);
    const openRequest = (request_id) => {
        if (request_id) {
            navigate(`/admin/request/${request_id}`);
        }
    }

    const handleConfirmOpen = (request) => {
        setConfirmData(request);
        setConfirmOpen(true);
    }

    const [paymentViewOpen, setPaymentViewOpen] = useState(false);
    const [paymentViewData, setPaymentViewData] = useState(null);

    const handleViewPayment = (payment) => {
        setPaymentViewData(payment);
        setPaymentViewOpen(true);
    }

    const columns = [
        {
            title: 'Mã đề nghị',
            label: 'Mã đề nghị',
            dataIndex: 'request_code',
            key: 'request_code',
            hidden: false,
            render: (text, record) => (
                <Dropdown
                    trigger={['click']}
                    on
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <Button size="small" type="link" onClick={() => openRequest(record.parent)}>Xem yêu
                                        cầu</Button>),
                                icon: <EyeOutlined/>

                            },
                            record.status ?
                                {
                                    key: '2',
                                    label: (<Button type="link" size="small" onClick={() => handleViewPayment(record)}>Xem
                                        thông tin thanh toán</Button>),
                                    icon: <FileSyncOutlined/>
                                }
                                :
                                {
                                    key: '2',
                                    label: (<Button type="link" danger size="small"
                                                    onClick={() => handleConfirmOpen(record)}>Xác nhận thanh
                                        toán</Button>),
                                    icon: <DollarOutlined/>
                                }
                        ]
                    }}
                >
                    <span className="link-text">{record.request_code}</span>
                </Dropdown>
            )
        },
        {
            title: 'Người đề nghị',
            label: 'Người đề nghị',
            dataIndex:
                'request_by_name',
            key:
                'created_by_name',
            hidden: false,
        }
        ,
        {
            title: 'Bộ phận đề nghị',
            label: 'Bộ phận đề nghị',
            dataIndex:
                'request_department',
            key:
                'request_department',
            hidden: false,
        },
        {
            title: 'Loại chi phí',
            label: 'Loại chi phí',
            dataIndex:
                'expense_type',
            key:
                'expense_type',
            hidden: false,
            render:
                (text, record) => (
                    <span>{record.expense_type}</span>
                )
        },
        {
            title: 'Diễn giải',
            label: 'Diễn giải',
            dataIndex:
                'description',
            key:
                'description',
            hidden: false,
            render:
                (text, record) => (
                    <ExpandableText text={text}/>
                )
        },
        {
            title: 'Mã/PO',
            label: 'Mã LSX/IPO/DPO',
            dataIndex:
                'special_code',
            key:
                'special_code',
            hidden: false,
            render:
                (text, record) => (
                    <ExpandableText text={text}/>
                )
        },
        {
            title: 'Số tiền',
            label: 'Số tiền',
            dataIndex:
                'amount',
            key:
                'amount',
            hidden: false,
            align: 'right',
            render:
                (text, record) => {
                    if (record.currency_code !== 'VND') {
                        //if the currency is not 'VND', apply two decimal places if the value does not have decimal places
                        //else display normally
                        return (
                            <span>{formatNumber(record.amount, 2)}</span>
                        )
                    } else {
                        return (
                            <span>{formatNumber(record.amount)}</span>
                        )
                    }
                }
        },
        {
            title: 'Quy đổi VNĐ',
            label: 'Quy đổi VNĐ',
            dataIndex:
                'vnd_amount',
            key:
                'vnd_amount',
            hidden: false,
            align: 'right',
            render:
                (text, record) => {
                    return (
                        <span>{formatNumber(record.amount)}</span>
                    )
                }
        },
        {
            title: 'Loại tiền',
            label: 'Loại tiền',
            dataIndex:
                'currency_code',
            key:
                'currency_code',
            hidden: false,
        },
        {
            title: 'Tên TK',
            label: 'Tên TK',
            dataIndex:
                'receiver_name',
            key:
                'receiver_name',
            hidden: false,
            render:
                (text, record) => (
                    <ExpandableText text={text}/>
                )
        },
        {
            title: 'Số TK',
            label: 'Số TK',
            dataIndex:
                'bank_account',
            key:
                'bank_account',
            hidden: false,
        },
        {
            title: 'Ngân hàng',
            label: 'Ngân hàng',
            dataIndex:
                'bank_name',
            key:
                'bank_name',
            hidden: false,
            render:
                (text, record) => (
                    <ExpandableText text={text}/>
                )
        },
        {
            title: 'TC xác nhận',
            label: 'Tài chính xác nhận',
            dataIndex:
                'created_by_name',
            key:
                'created_by_name',
            hidden: false,
        },
        {
            title: 'Ngày TC xác nhận',
            label: 'Ngày tài chính xác nhận',
            export_rule: (date) => dayjs(date).format('DD-MM-YYYY'),
            dataIndex:
                'payment_date',
            key:
                'payment_date',
            hidden: false,
            render:
                (text, record) => (
                    <span>{dayjs(record.payment_date).format('DD-MM-YYYY')}</span>
                )
        },
        {
            title: 'KT duyệt',
            label: 'Kế toán duyệt hồ sơ',
            dataIndex: 'accountant_id',
            key: 'accountant_id',
            export_rule: (id) => {
                //find fullname in siteConfig.personel array with object prop uid match text
                const ketoan = siteConfig?.personnel?.find(item => {
                        return item.uid === Number(id)
                    }
                )

                return ketoan ? ketoan.fullname : ''
            },
            hidden: false,
            render: text => {
                //find fullname in siteConfig.personel array with object prop uid match text
                const ketoan = siteConfig?.personnel?.find(item => {
                        return item.uid === Number(text)
                    }
                )

                return (<span>{ketoan ? ketoan.fullname : ''}</span>)
            }
        },
        {
            title: 'Ngày đi tiền',
            label: 'Ngày đi tiền',
            export_rule: (date) => dayjs(date).format('DD-MM-YYYY'),
            dataIndex:
                'money_transfer_at',
            key:
                'money_transfer_at',
            hidden: false,
            render:
                (text, record) => (
                    <span>{record.money_transfer_at ? dayjs(record.money_transfer_at).format('DD-MM-YYYY') : "-"}</span>
                )
        },
        {
            title: 'Người đi tiền',
            label: 'Người đi tiền',
            dataIndex: 'money_transfer_by_name',
            key: 'money_transfer_by_name',
            hidden: false,
        },
        {
            title: 'Tài khoản',
            label: 'Tài khoản đi tiền',
            dataIndex:
                'transfer_from_account',
            key:
                'transfer_from_account',
            hidden: false,
        }
        ,
        {
            title: 'Tình trạng',
            label: 'Tình trạng',
            export_rule: (status) => status === true ? 'Đã đi tiền' : 'Chưa đi tiền',
            dataIndex:
                'status',
            key:
                'status',
            hidden: false,
            render:
                (text, record) => (
                    <Tag
                        color={record.status === true ? 'green' : 'red'}>{record.status === true ? 'Đã đi tiền' : 'Chưa đi tiền'}</Tag>
                )
        }
        ,
    ]

    const changeView = () => {
        processTableColumns('payment_admin_column', 'payment_admin_column_order', columns, setTableColumns);
    }

    const onColumnViewChange = () => {
        changeView()
    }

    useEffect(() => {
        changeView()
    }, []);


    const handleFilterClose = () => {
        setFilterOpen(false);
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    }
    const fetchPayList = (currentPage = 1, pageSize = 10, forceFilter = null) => {
        setTableLoading(true);

        if (forceFilter) {
            //if transfer_from_account prop is an Array and have only 1 item with value is equal to "0", remove it
            if (forceFilter.transfer_from_account && Array.isArray(forceFilter.transfer_from_account) && forceFilter.transfer_from_account.length === 1 && forceFilter.transfer_from_account[0] === "0") {
                delete forceFilter.transfer_from_account;
            }
        }

        api.post('/request/process/payment/list', {
            currentPage,
            pageSize,
            filter: forceFilter || filter
        }).then((response) => {
            if (response) {
                setPayList(response);
            }
        }).finally(() => {
                setTableLoading(false)
            }
        )
    }
    const handleSetFilter = (values) => {
        setFilter(values);
    }

    const getFilterFromStorage = () => {
        let payment_filter = {};
        try {
            payment_filter = JSON.parse(localStorage.getItem('payment_filter'));
            //convert date string to dayjs object

            /*
            if (payment_filter?.payment_date)
                payment_filter.payment_date = payment_filter.payment_date?.map((date) => dayjs(date));
                
             */
        } catch (err) {
            return {};
        }

        return payment_filter || {};
    }

    useEffect(() => {
        const payment_filter = getFilterFromStorage();
        setFilter(payment_filter)
        fetchPayList(1, 10, payment_filter)
    }, [reload]);


    const isRowSelectable = (record) => {
        return record.status === false;
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: !isRowSelectable(record),
        }),
    };

    const exportFile = useCallback(() => {

        /*
        const excelData = payList.rows.map((row, index) => ({
            'STT': index + 1,
            'Mã đề nghị': row.request_code,
            'Phòng ban': row.request_department,
            'Người đề nghị': row.request_by_name,
            'Loại chi phí': row.expense_type,
            'Cấp nguồn bởi': row.created_by_name,
            'Số tiền': row.amount,
            'Loại tiền': row.currency_code,
            'Tỉ giá': row.exchange_rate,
            'Ngày TC xác nhận': dayjs(row.payment_date).format('DD-MM-YYYY'),
            'Tài khoản đi tiền': row.transfer_from_account,
            'Thụ hưởng': row.receiver_name,
            'Tình trạng': row.status === true ? 'Đã đi tiền' : 'Chưa đi tiền',
            'Người chi tiền': row?.money_transfer_by_name || '',
            'Ngày KT đi tiền': row?.money_transfer_at ? dayjs(row.money_transfer_at).format('DD-MM-YYYY') : '',
        }))
         */

        const excelData = payList.rows.map((row, index) => (
            tableColumns.filter((column) => !column.hidden).reduce((obj, column) => {
                let value = row[column.dataIndex];

                if (column.export_rule) {
                    value = column.export_rule(value);
                }

                obj[column.label] = value;
                return obj;
            }, {STT: index + 1})
        ));

        const ws = utils.json_to_sheet(excelData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");

        //Generate file name based on date
        const date = new Date();
        const fileName = `Danh sách chi tiền ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`;
        writeFileXLSX(wb, fileName);

        notify('success', 'Đã tải xuống file: ' + fileName, 3);
    }, [payList, tableColumns]);


    return (
        <div>
            <h1>Danh sách chi tiền đã cấp nguồn</h1>
            <div className="text-end mb-3">
                <Space>
                    <Button
                        icon={<CloudDownloadOutlined/>}
                        onClick={() => {
                            //use xlsx to export data to excel
                            exportFile();
                        }}
                    >Tải về Excel</Button>
                    <Search
                        allowClear
                        onSearch={(value) => {
                            const search = value.trim();

                            fetchPayList(1, 10, {
                                ...filter,
                                search: search !== '' ? search : null
                            })
                        }}
                    />
                    <Badge count={Object.keys(filter).length || 0}>
                        <Button onClick={setFilterOpen} className="rounded-2" type="primary" icon={<FilterOutlined/>}/>
                    </Badge>
                    <Button icon={<TableOutlined/>} onClick={() => setColFilterOpen(true)}
                            title="Cá nhân hóa cột hiển thị"/>
                    <Button
                        className="rounded-2 ms-2"
                        icon={<AppstoreAddOutlined/>}
                        title="Duyệt đi tiền theo các dòng được chọn"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => setBulkOpen(true)}
                    />
                </Space>
            </div>
            <Table
                loading={tableLoading}
                rowSelection={rowSelection}
                columns={tableColumns.filter((column) => !column.hidden)}
                dataSource={payList?.rows}
                scroll={
                    {
                        x: 'max-content',
                        y: 'calc(100vh - 300px)',
                        scrollToFirstRowOnChange: true
                    }
                }
                sc
                pagination={{
                    onChange: ((page, pageSize) => {
                        setSelectedRowKeys([]);
                        fetchPayList(page, pageSize);
                    }),
                    total: payList?.totalRows,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} dòng`,
                }}
                summary={(pageData) => {
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="bg-light">
                                <Table.Summary.Cell index={0}/>
                                <Table.Summary.Cell index={1} colSpan={4}>
                                    <span className="text-bold">Tổng tiền cần chi</span>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={columns.length - 3}>
                                    <span
                                        className="text-bold">{formatNumber(pageData.reduce((total, item) => total + item.amount, 0))}</span>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            />
            <PaymentListFilter
                open={filerOpen}
                onClose={handleFilterClose}
                setFilter={handleSetFilter}
                onReload={() => setReload(!reload)}
            />
            <PaymentConfirm
                open={confirmOpen}
                onClose={handleConfirmClose}
                confirmData={confirmData}
                onReload={() => fetchPayList()}
            />
            <BulkPaymentConfirm
                bulkOpen={bulkOpen}
                onClose={() => setBulkOpen(false)}
                data={{payList, selectedRowKeys}}
                onReload={() => {
                    setSelectedRowKeys([]);
                    fetchPayList()
                }}
            />
            <PaymentView open={paymentViewOpen} onClose={() => setPaymentViewOpen(false)}
                         paymentData={paymentViewData}/>
            <TableColumnFilter open={colFilterOpen} onClose={() => setColFilterOpen(false)} columns={columns}
                               onColumnChange={onColumnViewChange}
                               keys={{view: 'payment_admin_column', order: 'payment_admin_column_order'}}/>
        </div>
    )
}

export default PaymentList