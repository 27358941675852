import {Link} from 'react-router-dom';
import {useEffect} from "react";
import {clearTokenAndRedirect, getAppToken, getSiteCookieToken} from "../../Helpers/jwt-helper";

const AuthPage = () => {
    const siteCookieToken = getSiteCookieToken();

    useEffect(() => {

        if (!siteCookieToken){
            //redirect to FLHome if cookie not found
            //redirect goes here and remove this line
            //setCookieToken('token', token);


            clearTokenAndRedirect()
            return;
        }

        //get current url, if 'localhost' or '192.168.168.59', add pernament token

        getAppToken(siteCookieToken)

    }, [])



    if (!siteCookieToken){
        return (
            <Link to="https://home.forlife.vn">
                Vui lòng bấm vào đây để đăng nhập
            </Link>
        )
    }


}

export default AuthPage