export const displayData = (data, prefix) => {
    if (data){
        return ` ${prefix} ${data}`
    }

    return '';
}

export const tmpKey = 'expense_tmp'

export const cleanUpTmp = () => {
    window.localStorage.removeItem(tmpKey);
}

export const testData = (data) => {
    //Test if data object's properties are not null: depature, destination, travelDate, fullname, amount, distance, vehicle
    if (data?.departure && data?.destination && data?.travelDate && data?.fullname && data?.amount && data?.distance && data?.vehicle){
        return true;
    }

    return false;
}