export const REQUEST_STATUS = {
    new: {code: 'new', label: 'Mới tạo', color: 'magenta'},
    pending: {code: 'pending', label: 'Chờ xác nhận', color: 'gold'},
    return_to_requester: {code: 'return_to_requester', label: 'Chưa đủ điều kiện', color: 'red'},
    waiting_for_process: {code: 'waiting_for_process', label: 'Chờ cấp nguồn', color: 'green'},
    partial_payment: {code: 'partial_payment', label: 'Thanh toán từng phần', color: 'blue'},
    full_payment: {code: 'full_payment', label: 'Thanh toán toàn bộ', color: 'cyan'},
    finished: {code: 'finished', label: 'Hoàn thành', color: 'purple'},
    cancelled: {code: 'cancelled', label: 'Đã huỷ', color: 'red'},
}

export const ROLES = ['tc', 'kt', 'admin'];