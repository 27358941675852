import {Button, Flex, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useRequestPaymentLineStore, useRowSelection, useTravelExpenseStore} from "../stores";
import ExpenseFormWizard from "./ExpenseFormWizard";

const FormData = ({activeStep}) => {
    const [inputFormOpen, setInputFormOpen] = useState(false);
    const [editingRow, setEditingRow] = useState(false);

    const handleInputFormOpen = () => {
        setEditingRow(null);
        setInputFormOpen(true);
    }

    const handleInputFormClose = () => {
        setInputFormOpen(false);
    }

    const dummyData = Array.from({length: 30}, (_, index) => ({
        key: index,  // Unique key for each row
        fullname: `Employee ${index + 1}`,
        position: `Position ${index + 1}`,
        company: `Company ${index + 1}`,
    }));

    const {paymentRequestDetail} = useTravelExpenseStore();
    const {setData} = useRequestPaymentLineStore()

    const columns = [
        {
            title: 'Họ tên',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Từ ngày',
            dataIndex: 'from_date',
            key: 'from_date',
            render: (text) => text.format('DD/MM/YYYY')
        },
        {
            title: 'Đến ngày',
            dataIndex: 'to_date',
            key: 'to_date',
            render: (text) => text.format('DD/MM/YYYY')
        },
    ]

    const handleRowEdit = () => {
        //Get selectedRowKeys and set data
        const lineData = paymentRequestDetail.find(line => line.key === selectedRowKeys[0])
        setData(lineData)
        setEditingRow(lineData.key)
        setInputFormOpen(true)
    }

    /*
    useEffect(() => {
        setSelectedRowKeys([]);
        setExpandedRowKeys([])
    }, [activeStep])
     */

    const TableFooter = () => {
        return (
            <Flex
                justify="space-between"
                align="start"
            >
                <div>Tổng cộng:</div>
                <Space>
                    <Button
                        icon={<DeleteOutlined/>}
                        size="small"
                        type="text"
                        disabled={!isRowSelected}
                        className="me-3"
                        danger
                    />
                    <Button
                        icon={<EditOutlined/>}
                        disabled={!isRowSelected}
                        size="small"
                        onClick={handleRowEdit}
                    />
                    <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        size="small"
                        onClick={handleInputFormOpen}
                    />
                </Space>
            </Flex>
        )
    }

    const [selectedRowKeys, setSelectedRowKeys, rowSelection] = useRowSelection();

    const onRowClick = (record, rowIndex) => {
        setSelectedRowKeys(prevKeys => { // Use callback to access latest state
            if (prevKeys.includes(record.key)) {
                return [];
            } else {
                return [record.key];
            }
        });
    }

    const isRowSelected = selectedRowKeys.length > 0;

    return (
        <>
            <Table
                columns={columns}
                dataSource={paymentRequestDetail}
                footer={() => (<TableFooter/>)}
                size="small"
                scroll={{y: 240}}
                pagination={{
                    hideOnSinglePage: true,
                }}
                rowSelection={rowSelection}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => onRowClick(record, rowIndex), // click row
                    }
                }}
                /*
                expandable={{
                    expandedRowRender: (item) => (<span>Hello</span>),
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedRows) => {
                        setExpandedRowKeys(expandedRows)
                    }
                }}

                 */
            />
            <ExpenseFormWizard open={inputFormOpen} close={handleInputFormClose} editingRow={editingRow}/>
        </>
    )
}

export default FormData;