import {Col, Form, Input, Row, Space} from "antd";
import {processFormValues, useRequestPaymentLineStore} from "../../../stores";
import {IconLanding,  IconTakeOff} from "../../../../Icons";
import {useEffect} from "react";

export const TravelTime = ({}) => {
    const [form] = Form.useForm();

    const iconSize = {
        width: 18,
        height: 18
    }

    const {line, setData} = useRequestPaymentLineStore();

    const handleChanges = () => {
        processFormValues(form)
        setData(form.getFieldValue());
    }

    useEffect(() => {
        if (line.length === 0){
            form.resetFields();
        } else {
            form.setFieldsValue(line)
        }
    }, [line])

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Space>
                <span>Thời gian di chuyển</span>
            </Space>
            <Row gutter={4}>
                <Col span={12}>
                    <Form.Item
                        name="transportation_cost"
                        label={
                            <Space>
                                <IconTakeOff style={{...iconSize}}/>
                                <span>Chiều đi</span>
                            </Space>
                        }
                        className="narrow-form-item"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={<Space>
                            <IconLanding style={{...iconSize}}/>
                            <span>Chiều về</span>
                        </Space>}
                        className="narrow-form-item"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
