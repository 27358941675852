import {Button, Image, Layout, Menu, App, Spin, Space,  Grid} from 'antd';
import {useMenuInfo} from "../Menu";
import {useNavigate} from "react-router-dom";
import { useState} from "react";
import {
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, UserOutlined, WarningOutlined,
} from "@ant-design/icons";
import {useUserInfo} from "../../Helpers/user-session-helper";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import {shallow} from "zustand/shallow";

const {Content, Sider, Header} = Layout;

const UserInfoCorner = (props) => {
    const {name} = props.user;

    //const [open, setOpen] = useState(false);

    return (
        /*
        <Popover
            content={<PopOverContent userInfo={props.user}/>}
            trigger="click"
            open={open}
            onOpenChange={() => setOpen(true)}
        >*/
            <Space>
                <UserOutlined/>
                <span>{name}</span>
            </Space>
        //</Popover>
    )
}
const AppLayout = ({children}) => {
    const {modal} = App.useApp();
    const { useBreakpoint } = Grid;

    const screens = useBreakpoint();

    const userInfo = useUserInfo();
    const [loading, setLoading] = UseLoading(
        (state) => [state.loading, state.setLoading],
        shallow
    )

    const menu = useMenuInfo();

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);

    const backgroundColor = 'rgba(35, 48, 68, 1)';
    const contentBackgroundColor = 'rgba(255, 255, 255, 1)';
    const gradientBackground = '';//'linear-gradient(90deg, rgba(35,48,69,1) 14%, rgba(201,32,39,1) 100%)';

    const menuClicked = (menuKey) => {
        if (menuKey?.key && menuKey.key === 'logout') {
            modal.confirm({
                title: 'CHÚ Ý',
                content: 'Có chắc chắn muốn thoát không?',
                icon: <WarningOutlined className="text-xxl"/>,
                onOk: () => {
                    navigate("/logout/?force=false")
                }
            });

            return;
        }
        navigate(menuKey?.key)
    }

    const toLogin = () => {
        window.location.href = 'https://home.forlife.vn?redirect_url=' + window.location.href;
    }

    return (
        <Spin size="large" tip="Xin vui lòng đợi" spinning={loading} style={{background: 'transparent', zIndex: 9999} }>
            <Layout
                hasSider
                style={{
                    overflow: 'auto',
                }}
                className="app-layout"
            >
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed || !screens.lg}
                    width={260}
                    style={{
                        background: backgroundColor,
                    }}
                >
                    <div style={{margin: '20px 0px 8px 0px'}} className="text-center">
                        <Image
                            src="/LOGO-FORLIFE-02-white.png" height={48}
                            preview={false}
                            onClick={() => navigate('/')}
                            className="hand"
                            style={{
                                display: collapsed || !screens.lg ? 'none' : 'block'
                            }}
                        />
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        items={userInfo.uid ? menu.menuItems : []}
                        onClick={menuClicked}
                        style={{
                            background: backgroundColor
                        }
                        }
                    />
                </Sider>
                <Layout
                    style={{
                        background: gradientBackground
                    }}
                >
                    <Header
                        style={{
                            padding: 0,
                            alignItems: 'center',
                            height: '50px',
                            background: userInfo.test ? 'lightcoral' : 'white',
                            borderBottom: '1px solid #f7f7f7',
                        }}
                        className="items-start-end"
                    >
                        <Space>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 48,
                                    height: 48,
                                }}
                            />
                            <span className="text-l text-bold">Quản lý đề nghị thanh toán {userInfo.test ? <span>(TEST MODE)</span> : ''}</span>
                        </Space>
                        <div style={{margin: '0px 15px', fontWeight: 'bold'}}>
                            {userInfo.name ? <UserInfoCorner user={userInfo} />: <Button onClick={toLogin} icon={<HomeOutlined/>}>Đăng nhập
                        </Button>
                        }
                    </div>
                </Header>
                <Content
                    style={{
                        padding: 24,
                        background: contentBackgroundColor,
                        borderTopLeftRadius: '8px'
                    }}
                >
                    {children}
                </Content>

            </Layout>
        </Layout>
</Spin>

)
}

export default AppLayout
