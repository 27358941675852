import {Button, Image, Upload, App, Spin} from "antd";
import {CameraOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import Camera from "./Camera";
import {useFormState, useTravelExpenseStore} from "../stores";
import Compressor from 'compressorjs';


const ImageUpload = () => {
    const MAX_FILE_COUNT = 9;
    const MAX_FILE_SIZE = 2 //Mb
    const MEDIA_URL = 'https://media-thanhtoan.forlife.zone'

    const {message} = App.useApp();

    const [fileList, setFileList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [webcamOpen, setWebcamOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const {formOpen} = useFormState();
    const {setFiles} = useTravelExpenseStore()
    const uploadRef = useRef(null);

    const captureImage = () => {
        setWebcamOpen(true)
    }

    const uploadClickEvent = (e) => {
        e.stopPropagation(); // Prevent the default behavior of the upload button
        captureImage()     // Trigger your custom photo capture logic
    }

    useEffect(() => {
        const userAgent = navigator.userAgent || window.opera;
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        setIsMobile(isMobileDevice);

        if (isMobileDevice && uploadRef.current) {
            const uploadButton = document.querySelector('.ant-upload');
            if (uploadButton) {
                uploadButton.addEventListener('click', uploadClickEvent);
            }
        }

        return () => {
            //clear file list and event listener
            setFileList([]);
            if (isMobileDevice) {
                const uploadButton = document.querySelector('.ant-upload');
                if (uploadButton) {
                    uploadButton.removeEventListener('click', uploadClickEvent);
                }
            }
        }

    }, [formOpen]);

    const uploadButton = (
        <div>
            {isMobile ? (
                <Button type="text" icon={<CameraOutlined/>} onClick={captureImage}/>
            ) : (
                <>
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>Upload</div>
                </>
            )}
        </div>
    );

    const handleChange = ({file, fileList, event}) => {
        //console.log("File:", file, "\n\nFileList:", fileList)
        //remove any file is done uploading but has error
        fileList = fileList.filter(file => {

            if (file.status === 'error') {
                message.error('Lỗi khi upload file: ' + file.name)
                return false
            }

            return true
        });

        //Check if current file is done
        if (file.status === 'done') {
            file.url = `${MEDIA_URL}/${file.response.filename}`
            file.name = file.response.filename
        }

        //Replace the file list with the new one based on uid prop
        const newFileList = fileList.map(_file => {
            if (_file.uid === file.uid) {
                return file
            }

            return _file
        })

        setFileList(newFileList)
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    }

    const uploadFile = (file, onSuccess, onError) => {
        const formData = new FormData();
        formData.append('image', file); // 'file' is your field name

        fetch('https://upload.forlife.zone/upload', {
            method: 'POST',
            body: formData
        })
            .then(async response => {
                if (response.ok) {
                    // 3. Handle success (e.g., get the uploaded file URL)
                    const data = await response.json()
                    if (!data.error) {
                        response.filename = data.file
                    }

                    onSuccess(response);
                } else {
                    // 4. Handle errors
                    onError(response);
                }
            })
            .catch(error => {
                onError(error);
            });
    }

    useEffect(() => {
        if (!fileList) {
            return
        }

        if (fileList.length === 0) {
            setFiles([])
            return
        }

        setFiles(fileList.map(_file => {
            if (_file.status !== 'done') {
                return false
            }

            return {
                name: _file.name,
                size: _file?.size,
                thumbUrl: _file?.thumbUrl,
                type: _file?.type,
                uid: _file.name.split('.')[0],
                url: _file.url
            }
        }).filter(Boolean))

    }, [fileList]);

    const _uploadFile = (file, newFile) => {
        uploadFile(file, (response) => {
                const prevUID = newFile.uid

                newFile.uid = response.filename.split('.')[0]
                newFile.name = response.filename
                newFile.status = 'done'
                newFile.url = `${MEDIA_URL}/${response.filename}`

                setFileList((prev) => prev.map(_file => {
                    if (_file.uid === prevUID) {
                        return newFile
                    }

                    return _file
                }))

            },
            (error) => {
                message.error('Lỗi khi upload file')

                //remove the file from the list
                setFileList((prev) => prev.map(_file => {
                    if (_file.uid === newFile.uid) {
                        return false
                    }

                    return _file
                }).filter(Boolean))
            }
        )
    }

    const onCameraPicture = async (imageSrc) => {
        const newFile = {
            uid: `rc-upload-${new Date().getTime()}`,
            name: 'Photo-' + new Date().getTime(),
            status: 'uploading',
            url: null,
            preview: null,
        }

        //Add temporary file to the list with uploading status
        setFileList((prev) => [...prev, newFile]);

        const fileBlob = await fetch(imageSrc).then(res => res.blob());
        const file = new File([fileBlob], `${new Date().getTime()}.jpg`, {type: 'image/jpeg'});

        //Compress the image if file size is greater than 2MB
        if (file.size > 512*1024) {
            new Compressor(file, {
                quality: 0.8,
                success(result) {
                    _uploadFile(result, newFile)
                },
                error(err) {
                    message.error('Lỗi khi upload file')
                },
            });
        } else {
            _uploadFile(file, newFile)
        }
    }

    const beforeUpload = (file) => {
        //Check for file extension and file size, if not JPG, JPEF, PNG or file size is greater than 2MB, return error
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
            message.error('Định dạng file không hợp lệ. Vui lòng chọn file JPG, JPEG hoặc PNG.');
            return false
        }

        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            message.error('Kích thước file quá lớn, vui lòng chọn file nhỏ hơn 2MB');
            return false
        }

        return true
    }

    const customRequest = ({file, onSuccess, onError}) => {
        uploadFile(file, onSuccess, onError)
    };

    const removeFile = async (file) => {
        //Sleep for 2 seconds
        setLoading(true)

        try {
            const deleteFile = await fetch('https://upload.forlife.zone/delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({files: [file.name]})
            })

            if (deleteFile && deleteFile.ok) {
                setLoading(false)
                return true
            } else {
                setLoading(false)
                message.error('Lỗi khi xóa file')
                return false
            }
        } catch (error) {
            setLoading(false)
            message.error('Lỗi khi xóa file:', error.message)
            return false
        }
    }

    return (
        <Spin spinning={loading}>
            <Upload
                accept=".jpg,.jpeg.png"
                ref={uploadRef}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload} // Prevent default upload behavior
                customRequest={customRequest} // Additional safeguard for default upload behavior
                maxCount={MAX_FILE_COUNT}
                onRemove={removeFile}
            >
                {fileList.length >= MAX_FILE_COUNT ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}

            <Camera open={webcamOpen} onClose={() => setWebcamOpen(false)} onPicture={onCameraPicture}/>
        </Spin>
    );
}

export default ImageUpload;