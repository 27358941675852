import {useEffect} from "react";
import {clearTokenAndRedirect, jwtCheck} from "../../Helpers/jwt-helper";
import {Skeleton} from "antd";
import {useUserInfo} from "../../Helpers/user-session-helper";

const ProtectedRoute = ({children}) => {

    const userInfo = useUserInfo();

    useEffect(() => {
        const jwt = jwtCheck();
        if (!jwt)
            console.log("Invalid Token");

    }, [])

    return (
        <>
            {!userInfo.uid ? <Skeleton active /> : children}
        </>
    )
}

export default ProtectedRoute