import {
    Alert,
    AutoComplete,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Grid,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space, Tooltip
} from "antd";
import {sanitizeData, useTravelExpenseStore} from "../stores";
import {useEffect, useLayoutEffect, useState} from "react";
import dayjs from "dayjs";
import {CloseOutlined, QuestionCircleOutlined, SaveOutlined} from "@ant-design/icons";
import {tmpKey} from "./helper";
import {IconCar, IconLanding, IconNote, IconRoad, IconTakeOff, IconTicket} from "../../Icons";

const ExpenseLineForm = ({expense, open, close, done}) => {
    const breakpoints = Grid.useBreakpoint();
    const colGutter = 8

    const {personnel, addDetailSheetLine, detailSheet, updateDetailSheetLine, getPlacesList, getTransportTypeList, getNoteList} = useTravelExpenseStore();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
    const [form] = Form.useForm();

    const isEditing = expense !== null && expense !== undefined;

    const handleChange = (option = null) => {
        const uid = form.getFieldValue('uid')

        if (uid && personnel.length > 0) {
            const employee = personnel.find(person => person.uid === uid)
            form.setFieldValue('fullname', employee.fullname)
        }

        const formValues = sanitizeData(form.getFieldsValue())
        setSubmitButtonDisabled(!formValues?.uid)

        //window.localStorage.setItem(tmpKey, JSON.stringify(formValues));
    }

    const handleClose = () => {
        done()
        close()
        form.resetFields()
        setSubmitButtonDisabled(true)
        //window.localStorage.removeItem(tmpKey)

    }

    const handleSave = () => {
        if (isEditing) {
            updateDetailSheetLine(expense, sanitizeData(form.getFieldsValue()))
        } else {
            addDetailSheetLine(sanitizeData(form.getFieldsValue()))
        }

        handleClose()
    }

    useEffect(() => {
        if (open) {
            //If expense prop is undefined or null, it's adding mode

            if (!isEditing) {
                /*
                const expense_tmp = JSON.parse(window.localStorage.getItem(tmpKey));
                if (expense_tmp) {
                    if (expense_tmp.travelDate) {
                        //Test if travelDate is a a valid date
                        const date = dayjs(expense_tmp.travelDate)

                        if (date.isValid())
                            expense_tmp.travelDate = date
                        else
                            delete expense_tmp.travelDate
                    }

                    form.setFieldsValue(expense_tmp);
                } else {
                    form.resetFields()
                }
                 */

                form.resetFields()
                return;
            }

            //Editing mode, get data from expense prop
            //Get data from detailSheet array by index
            form.setFieldsValue(detailSheet[expense])
            setSubmitButtonDisabled(!detailSheet[expense]?.uid)
        }

    }, [open, expense])

    const fieldTooltip = (title, content) => (
        <div>
            <span>{title}</span>
            <Tooltip
                color="blue"
                arrow={false}
                title={content}>
                <Button
                    size="small"
                    type="link"
                    icon={<QuestionCircleOutlined/>}
                />
            </Tooltip>
        </div>
    )

    return (
        <Modal
            open={open}
            title="Nhập thông tin chi phí di chuyển"
            onCancel={handleClose}
            footer={
                <Space>
                    <Button
                        icon={<CloseOutlined/>}
                        onClick={handleClose}
                    >
                        Bỏ qua
                    </Button>
                    <Button
                        danger
                        type="primary"
                        icon={<SaveOutlined/>}
                        onClick={handleSave}
                        disabled={submitButtonDisabled}
                    >
                        Nhập chi phí
                    </Button>
                </Space>
            }
        >
            <Card
                size="small"
                type="inner"
            >
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Row gutter={[colGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label="Tên nhân viên"
                                name="uid"
                                className="narrow-form-item"
                            >
                                <Select
                                    showSearch
                                    placeholder="Tên nhân viên"
                                    style={{width: '100%'}}
                                    options={
                                        personnel.map(person => ({
                                            label: person.fullname,
                                            value: person.uid
                                        }))
                                    }
                                    onChange={handleChange}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{display: 'none'}}
                                name="fullname"
                                className="narrow-form-item"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ngày"
                                name="travelDate"
                                className="narrow-form-item"
                            >
                                <DatePicker
                                    allowClear
                                    format="DD/MM/YYYY"
                                    placeholder="DD/MM/YYYY"
                                    style={{width: '100%'}}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: submitButtonDisabled ? 'block' : 'none'}} className="mb-3">
                        <Col span={24}>
                            <Alert
                                message="Vui lòng chọn nhân viên để tiếp tục. Hoặc quay lại bước 1 để thêm người vào danh sách"
                                type="error"/>
                        </Col>
                    </Row>

                    <Row gutter={colGutter}>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label={fieldTooltip('Điểm đi', 'Điểm đi, điểm đến cần ghi địa chỉ cụ thể để có cơ sở truy xuất dữ liệu (Tên CH/TT/VP công ty..)')}
                                name="departure"
                                className="narrow-form-item"
                            >
                                <AutoComplete
                                    options={
                                        //Combine all departure and destination from detailSheet array
                                        //[...new Set([...new Set(detailSheet.map(item => item?.departure)), ...new Set(detailSheet.map(item => item?.destination))].filter(item => item))].map(item => ({value: item}))
                                        getPlacesList()
                                    }
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onBlur={handleChange}
                                >
                                    <Input
                                        prefix={<IconTakeOff/>}
                                        placeholder="Nhập điểm đi"
                                        allowClear
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label="Điểm đến"
                                name="destination"
                                className="narrow-form-item"
                            >
                                <AutoComplete
                                    //populate data from detailSheet array, getting vehicle property as an array
                                    options={
                                        //[...new Set([...new Set(detailSheet.map(item => item?.departure)), ...new Set(detailSheet.map(item => item?.destination))].filter(item => item))].map(item => ({value: item}))
                                        getPlacesList()
                                    }
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onBlur={handleChange}
                                >
                                    <Input
                                        prefix={<IconLanding/>}
                                        placeholder="Nhập điểm đến"
                                        allowClear
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={colGutter}>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label={fieldTooltip("Khoảng cách giữa hai điểm","Khoảng cách giữa 2 địa điểm được tính từ nơi làm việc của nhân sự (Có thể áp dụng khi di chuyển tại nơi ở phụ thuộc lịch trình công tác thuận tiện và khoảng cách đến địa điểm công tác gần hơn)")}
                                name="distance"
                                className="narrow-form-item"
                            >
                                <Input
                                    prefix={<IconRoad/>}
                                    placeholder="Khoảng cách"
                                    onBlur={handleChange}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label={fieldTooltip("Phương tiện sử dụng", "Với các trường hợp di chuyển bằng xe máy cá nhân cần ghi thông tin giá xăng hiện tại vào cột “Diễn giải")}
                                name="vehicle"
                                className="narrow-form-item"
                            >
                                <AutoComplete
                                    //populate data from detailSheet array, getting vehicle property as an array
                                    options={
                                        //[...new Set(detailSheet.map(item => item?.vehicle))].filter(item => item).map(item => ({value: item}))
                                        getTransportTypeList()
                                    }
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onBlur={handleChange}
                                >
                                    <Input
                                        prefix={<IconCar/>}
                                        placeholder="Loại phương tiện"
                                        allowClear
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={colGutter}>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label="Số vé/Nhà xe"
                                name="ticketNumber"
                                className="narrow-form-item"
                            >
                                <Input
                                    placeholder="Số vé/Nhà xe"
                                    prefix={<IconTicket/>}
                                    onBlur={handleChange}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={!breakpoints.md ? 24 : 12}>
                            <Form.Item
                                label={fieldTooltip("Số tiền", "Các trường hợp phát sinh chi phí ngoài các khoản mục được liệt kê trong quy định, cần thêm sự phê duyệt của giám đốc chức năng và ghi rõ chi tiết thông tin “Chi phí phát sinh ngoài quy định” vào cột 'Diễn giải'")}
                                name="amount"
                                className="narrow-form-item"
                            >
                                <InputNumber
                                    placeholder="Số tiền"
                                    style={{width: '100%'}}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    addonAfter={'VND'}
                                    onBlur={handleChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Diễn giải"
                                name="note"
                                className="narrow-form-item"
                            >
                                <AutoComplete
                                    //populate data from detailSheet array, getting note property as an array
                                    options={
                                        getNoteList()
                                    }
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onBlur={handleChange}
                                >
                                    <Input
                                        placeholder="Diễn giải (nếu có)"
                                        prefix={<IconNote/>}
                                        allowClear
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Card>
        </Modal>
    )
}

export default ExpenseLineForm;