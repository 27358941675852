import {useEffect, useRef, useState} from "react";
import {notify} from "../../../Helpers/toast-helper";
import api from "../../../Helpers/api-helper";
import Search from "antd/es/input/Search";
import {useTravelExpenseStore} from "../stores";

const InputEmployeeIdSearch = () => {
    const [employeeId, setEmployeeId] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const inputRef = useRef(null);

    const { addPersonnel, getPersonnelById} = useTravelExpenseStore();


    useEffect(() => {
        inputRef.current.focus();
        setEmployeeId('')
    }, []);

    const handleSearch = () => {
        //Return if employeeId is empty or not a number or string length < 3
        if (!employeeId || isNaN(employeeId) || employeeId.length < 4 || employeeId.length > 10) {
            return notify('error', 'Mã nhân viên không hợp lệ');
        }

        if (getPersonnelById(employeeId))
            return notify('error', 'Nhân viên đã có trong danh sách');

        setIsSearching(true)
        //Call API to search employee by employeeId
        api.post('/master/personnel_list/search', {uid: employeeId}).then(response => {
            if (response) {
                addPersonnel(response);
                notify('success', 'Nhân viên ' + response?.fullname + ' đã được thêm vào danh sách')
            }
        })
            .then(() => {
                setEmployeeId('');
            })
            .finally(() => {
                setIsSearching(false)
            });
    }

    return (
        <Search
            ref={inputRef}
            placeholder="Mã nhân viên"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value.trim())}
            onSearch={handleSearch}
            loading={isSearching}
            enterButton
        />
    )
}

export default InputEmployeeIdSearch;