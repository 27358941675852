import {Button, App, Modal, Space, Typography} from "antd";
import {useEffect, useState} from "react";
import FormSteps from "./FormSteps";
import {formSteps, modalMinWidth, useFormState, useTravelExpenseStore} from "./stores";
import {SaveOutlined} from "@ant-design/icons";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";
import dayjs from "dayjs";

const ModalFormWrapper = ({open, close, expenseId, reloadExpenseList}) => {

    const defaultTripTitle = '<Hồ sơ chưa có tên>';

    const [currentStep, setCurrentStep] = useState('1');
    const [tripTitle, setTripTitle] = useState(defaultTripTitle);
    const [formId, setFormId] = useState(null);

    const {reset, getData, setUpdated, updated, setData} = useTravelExpenseStore();
    const {modal} = App.useApp()

    const {setFormOpen} = useFormState();

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
            setFormOpen(true)
        }

        return () => {
            document.body.style.overflow = '';
            setTripTitle(defaultTripTitle)
            setFormOpen(false)
        }
    }, [open]);

    const handleClose = (e) => {
        const doCleanup = () => {
            setCurrentStep('1')
            setFormId(null)
            close();
            reset();
        }

        if (updated) {
            modal.confirm({
                title: 'Hồ sơ chưa được lưu',
                content: (<div><span>Bạn có chắc chắn muốn đóng không? Dữ liệu thay đổi sẽ không được lưu lại</span><p>Bấm <strong>"Bỏ qua"</strong> và bấm nút <strong>"Lưu"</strong> ở dưới để ghi dữ liệu. Hoặc bấm "Không lưu" nếu <span className="text-danger">không</span> muốn lưu dữ liệu.</p></div>),
                onOk: () => {
                    doCleanup()
                },
                okText: 'Không lưu',
                cancelText: 'Bỏ qua',
                okButtonProps: {
                    danger: true
                }
            })
        } else {
            doCleanup()
        }
    }

    const handleNext = () => {

        //If currentStep is last array item if formSteps, close modal
        if (currentStep === formSteps[formSteps.length - 1].key) {
            handleClose();
        } else {
            //Else, set currentStep to next item in formSteps array
            setCurrentStep(formSteps[formSteps.findIndex(item => item.key === currentStep) + 1].key)
        }
    }

    const handleBack = () => {
        //If currentStep is first item in formSteps array, set disabled prop to true
        if (currentStep !== formSteps[0].key) {
            //Else, set currentStep to previous item in formSteps array
            setCurrentStep(formSteps[formSteps.findIndex(item => item.key === currentStep) - 1].key)
        }
    }

    const handleSave = () => {
        //Save form data to database
        const data = {
            title: tripTitle,
            personnel: getData().personnel,
            detailSheet: getData().detailSheet,
            paymentRequestMeta: getData().paymentRequestMeta,
            paymentRequest: getData().paymentRequest,
            files: getData().files
        }

        if (!formId) {
            //Create new form
            api.post('/travel/expense/add', {data}).then(response => {

                if (response && response?.id) {
                    const {id} = response
                    setFormId(id)

                    notify('success', 'Hồ sơ công tác phí đã được tạo');
                    reloadExpenseList()
                }
            }).finally(() => {

            })
        }
        //Update existing form
        else {
            api.post('/travel/expense/update', {id: formId, data}).then(response => {
                if (response) {
                    notify('success', 'Hồ sơ công tác phí đã được cập nhật');
                    reloadExpenseList()
                }

            }).finally(() => {

            })
        }

        setUpdated(false);
    }

    useEffect(() => {
        if (expenseId && open) {

            api.get('/travel/expense/get/' + expenseId).then(response => {
                if (response) {
                    const {title, personnel, detailSheet, paymentRequest} = response

                    //convert travelDate in detailSheet to dayjs object
                    detailSheet.forEach((item, index) => {
                        if (detailSheet[index].travelDate) {
                            detailSheet[index].travelDate = dayjs(item.travelDate)
                        }
                    })

                    setData({title, personnel, detailSheet, paymentRequest})
                    setFormId(expenseId)
                    setTripTitle(title)
                }
            })
        }
    }, [expenseId]);

    const ModalFooter = () => {
        return (
            <div className="mt-3 d-flex justify-content-between">
                <Space>
                    <Button
                        onClick={handleSave}
                        danger
                        icon={<SaveOutlined/>}
                        disabled={!updated}
                    >
                        Lưu
                    </Button>
                </Space>
                <Space>
                    <Button
                        onClick={handleBack}
                        //If currentStep is first item in formSteps array, set disabled prop to true
                        disabled={currentStep === formSteps[0].key}
                        style={{display: currentStep === formSteps[0].key ? 'none' : 'block'}}
                    >
                        {'< Quay lại'}
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleNext}
                        //If currentStep is last array item if formSteps, set disabled prop to true
                        disabled={currentStep === formSteps[formSteps.length - 1].key}
                    >
                        {/*If currentStep is last array item if formSteps, display "Save"*/}
                        {/*{currentStep === formSteps[formSteps.length - 1].value ? 'Lưu' : 'Bước tiếp theo'}*/}
                        {'Bước tiếp theo >'}
                    </Button>
                </Space>
            </div>
        )
    }

    return (
        <Modal
            title="Tạo hồ sơ công tác phí"
            open={open}
            onCancel={handleClose}
            maskClosable={false}
            width={800}
            style={{
                top: '20px',
                minWidth: modalMinWidth
                }}
            styles={{
                body: {
                    height: '500px',
                    minHeight: '500px',
                }
            }}
            footer={<ModalFooter/>}
        >
            <Typography.Title
                type="primary"
                editable={{
                    onChange: (title) => {
                        title = title.trim();
                        if (title.length > 0) {
                            setTripTitle(title)
                            setUpdated(true)
                        }
                    },
                    maxLength: 64,
                    triggerType: ['icon', 'text']
                }}
                level={5}
                style={{
                    margin: 0,
                }}
                className="mb-3"
            >
                {tripTitle}
            </Typography.Title>
            <FormSteps currentStep={currentStep} setStep={setCurrentStep}/>

            {/*
            <hr className="hr-style"/>
            <Step1Personnel activeStep={currentStep}/>
            <Step2DetailSheet activeStep={currentStep}/>
            <Step3ReimbursementForm activeStep={currentStep}/>
            */}
        </Modal>
    )
}

export default ModalFormWrapper;