import {Col, DatePicker, Form, Modal, Row, Space} from "antd";
import {QuestionCircleFilled} from "@ant-design/icons";
import {useState} from "react";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";
import dayjs from "dayjs";

const BulkPaymentConfirm = ({bulkOpen, onClose, data, onReload}) => {

    const [loading, setLoading] = useState(false);
    const [paymentDate, setPaymentDate] = useState(dayjs());

    const handleClose = () => {
        onClose();
    }

    const handleOk = () => {
        //get _ids from payList.rows's index that exist in data.selectedRowKeys
        const _ids = data.payList.rows.filter((row, index) => {
            return data.selectedRowKeys.includes(index)
        }).map(row => row._id);

        //if _ids is empty, return
        if (_ids.length === 0) {
            return;
        }

        if (!paymentDate) {
            notify('error', 'Vui lòng chọn ngày chi tiền');
            return;
        }

        //call api to confirm payment
        setLoading(true);

        api.post('/request/process/payment/confirm', {_ids, note: null, payment_date: paymentDate}).then((response) => {
            if (response) {
                notify('info', response);
                onReload();
                //close modal
                handleClose();
            }
        }).finally(() => {
            setLoading(false);
        });

    }

    return (
        <Modal
            title="Xác nhận thanh toán hàng loạt"
            centered
            open={bulkOpen}
            onCancel={handleClose}
            confirmLoading={loading}
            onOk={handleOk}
        >
            <Row className="mt-4">
                <Col span={4} className="text-center">
                    <QuestionCircleFilled className="text-warning" style={{fontSize: '3em'}}/>
                </Col>
                <Col span={20}>
                    <Row>
                        <span className="">Có chắc chắn muốn xác nhận thanh toán hàng loạt các yêu cầu này không?</span>
                    </Row>
                    <Row>
                        <Space>
                            <span>Chọn ngày chi tiền</span>
                            <DatePicker
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                                //Set initial value to current date
                                defaultValue={dayjs()}
                            />
                        </Space>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default BulkPaymentConfirm