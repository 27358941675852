import {Button, Card, Checkbox, Col, Dropdown, List, Modal, Row} from "antd";
import {
    ControlOutlined,
    DragOutlined,
    FireOutlined,
    SortAscendingOutlined,
    StarOutlined,
    TableOutlined
} from "@ant-design/icons";
import 'simplebar-react/dist/simplebar.min.css';
import SimpleBar from "simplebar-react";
import {useEffect, useState} from "react";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import {
    restrictToFirstScrollableAncestor, restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {IconCheckAll, IconFolderDelete, IconThreeDot} from "../Icons";

const TableColumnFilter = ({open, onClose, columns, onColumnChange, keys}) => {

    const [columnSelect, setColumnSelect] = useState([]);
    const [dragItems, setDragItems] = useState([]);

    const cardStyle = {
        height: '100%',
        maxHeight: '600px',
    }

    const cardBodyStyle = {
        maxHeight: '450px',
        overflow: 'auto',
    }
    const handleClose = () => {
        onClose();
    }

    const onOk = () => {
        //save current columns selection and view order to local storage
        localStorage.setItem(keys.view, JSON.stringify(columnSelect));
        localStorage.setItem(keys.order, JSON.stringify(dragItems));
        onColumnChange()
        handleClose();
    }

    const getColumns = () => {
        return JSON.parse(JSON.stringify(columns.map(item => {
            return {
                dataIndex: item.dataIndex,
                label: item.label,
                hidden: item.hidden
            }
        })))
    }

    useEffect(() => {
        if (open) {
            //get current column selection from local storage
            const columnSelect = JSON.parse(window.localStorage.getItem(keys.view));
            //get current column order from local storage
            const columnOrder = JSON.parse(window.localStorage.getItem(keys.order));

            if (columnSelect)
                setColumnSelect(columnSelect);
            else
                setColumnSelect(getColumns);

            if (columnOrder) {
                //Compare current column order with columns to make sure all columns from columns are included
                //in columnOrder
                const columnOrderIds = columnOrder.map(i => i.dataIndex);
                const columnIds = columns.map(i => i.dataIndex);
                const missingColumns = columnIds.filter(i => !columnOrderIds.includes(i));
                if (missingColumns.length > 0) {
                    //if there are missing columns, get full data from columns and add to columnOrder
                    console.log('missing columns', missingColumns)
                    const missingColumnsData = columns.filter(i => missingColumns.includes(i.dataIndex));
                    columnOrder.push(...missingColumnsData);
                }

                setDragItems(columnOrder);
            } else {

                setDragItems(columnSelect || getColumns)
            }
        }
    }, [open]);

    useEffect(() => {
        //Loop through columnOrder and set hidden property for each column based on columnSelect
        if (dragItems && dragItems.length > 0) {
            const newDragItems = [...dragItems];

            newDragItems.forEach((item) => {
                const index = columnSelect.map(i => i.dataIndex).indexOf(item.dataIndex);
                item.hidden = columnSelect[index]?.hidden || false;

            })

            setDragItems(newDragItems);
        }
    }, [columnSelect]);


    const DragList = ({dragItems}) => {

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates,
            })
        );

        function handleDragEnd(event) {
            const {active, over} = event;

            if (active.id !== over.id) {
                setDragItems((items) => {
                    const oldIndex = (items.map(i => i.dataIndex)).indexOf(active.id);
                    const newIndex = (items.map(i => i.dataIndex)).indexOf(over.id);

                    return arrayMove(items, oldIndex, newIndex);
                });
            }
        }

        if (!dragItems || dragItems.length === 0)
            return null;

        return (
            <DndContext
                onDragEnd={handleDragEnd}
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext
                    items={dragItems.map((i) => i?.dataIndex)}
                >
                    {dragItems.map(value => {
                        if (value?.hidden)
                            return null;

                        return (
                            <SortableItem
                                handle={false}
                                key={value?.dataIndex}
                                id={value?.dataIndex}

                            >
                                <div className="drag-item">
                                    <span>{value?.label}</span>
                                    <DragOutlined/>
                                </div>
                            </SortableItem>
                        )
                    })}
                </SortableContext>
            </DndContext>
        )
    }

    const checkAll = (hidden) => {
        const newColumns = [...columnSelect];
        newColumns.forEach((column) => {
            column.hidden = hidden;
        })
        setColumnSelect(newColumns);
    }

    const resetFieldSort = () => {
        setDragItems(columnSelect);
    }

    const resetAll = () => {
        //delete all local storage
        localStorage.removeItem(keys.view);
        localStorage.removeItem(keys.order);
        //reset columnSelect and dragItems
        setColumnSelect(getColumns);
        setDragItems(getColumns);
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            onOk={onOk}
            okText="Lưu tùy chỉnh"
            cancelText="Bỏ qua"
            centered
            width={700}
            title="Cá nhân hóa bảng dữ liệu hiển thị"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Card
                        size={"small"}
                        title={
                            <span><TableOutlined/> Cột hiển thị</span>
                        }
                        style={cardStyle}
                        extra={
                            <Dropdown
                                key='111'
                                menu={{
                                    items: [
                                        {
                                            label: 'Chọn tất cả',
                                            icon: <IconCheckAll/>,
                                            onClick: () => checkAll(false),
                                            key: '1'
                                        }, {
                                            label: 'Bỏ chọn tất cả',
                                            icon: <FireOutlined/>,
                                            onClick: () => checkAll(true),
                                            key: '2'
                                        },
                                        {
                                            label: 'Về mặc định',
                                            icon: <IconFolderDelete />,
                                            onClick: () => resetAll(),
                                            key: '3'
                                        }
                                    ]
                                }}
                                placement="bottomRight"
                            >
                                <Button size="small" type="link" icon={<IconThreeDot/>}/>
                            < /Dropdown>
                        }
                    >
                        <SimpleBar scrollbarMaxSize="150" style={cardBodyStyle}>
                            <List
                                size="small"
                            >
                                {columnSelect.map((column, index) => (
                                    <List.Item
                                        key={index}
                                        extra={
                                            columnSelect[index].hidden ? null :
                                                <StarOutlined style={{color: 'cornflowerblue'}}/>
                                        }
                                    >
                                        <Checkbox
                                            defaultChecked={!columnSelect.hidden}
                                            checked={!columnSelect?.filter((_, idx) => idx === index)[0]?.hidden}
                                            onChange={(e) => {
                                                const newColumns = [...columnSelect];
                                                newColumns[index].hidden = !e.target.checked;
                                                setColumnSelect(newColumns)
                                            }}
                                        >
                                            {column.label}
                                        </Checkbox>
                                    </List.Item>
                                ))}
                            </List>
                        </SimpleBar>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        size={"small"}
                        title={<span><SortAscendingOutlined/> Sắp xếp</span>}
                        style={cardStyle}
                        extra={<Button icon={<ControlOutlined />} onClick={resetFieldSort} size="small" type="link" title="Về mặc định"/>}
                    >
                        <SimpleBar scrollbarMaxSize="150" style={cardBodyStyle}>
                            <DragList
                                dragItems={dragItems}
                            />
                        </SimpleBar>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}
export default TableColumnFilter