import { createWithEqualityFn } from 'zustand/traditional';
export const useUserInfo = createWithEqualityFn((set) => ({
    uid: null,
    role: null,
    name: null,
    department: null,
    mobile: null,
    position_title: null,
    telegram_id: null,
    test: false,
    setInfo: (uid, role, level, name, department, mobile, position_title) => set(() => ({uid, role, level, name, department, mobile, position_title})),
    setTest: (test) => set((prev) => ({...prev, test: test}))
}))