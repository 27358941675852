import {Modal, Space} from "antd";
import {IconQRScanner} from "../Icons";
import {useEffect, useRef, useState} from "react";

const QRScanModal = ({open, onClose}) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        //Delay 50ms before focus to prevent autofocus on mobile
        setTimeout(() => {
            inputRef?.current?.focus();
        }, 50);
    }, [open]);
    const handleClose = () => {
        setInputValue('');
        console.clear();
        console.log('Input cleared')
        onClose();
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        setInputValue(e.target.value);
    }

    return (
        <Modal
            centered={true}
            open={open}
            onCancel={handleClose}
            footer={null}
            width="300px"
        >
            <Space className="w-100">
                <IconQRScanner/>
                <div className="text-center w-100">
                    Vui lòng quét mã QR
                </div>
                <input
                    ref={inputRef}
                    onChange={handleChange}
                    className="offscreen-input"
                    value={inputValue}
                />
            </Space>
        </Modal>
    )
}

export default QRScanModal;