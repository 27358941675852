import {Button, Drawer, Form, Input, Space} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {notify} from "../../Helpers/toast-helper";
import api from "../../Helpers/api-helper";

const InputDrawer = ({open, onClose, title, initialValues, formSchema, dataKey, onReload, siteConfig}) => {
    const [form] = Form.useForm();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const formSubmit = () => {
        form.validateFields().then(values => {
            values.key = dataKey;
            setButtonDisabled(true);

            api.post(`/master/${dataKey}/add`, values).then(response => {
                if (response){
                    notify('info', response);
                    onReload();
                    DrawerClose();
                }
            }).finally(() => {
                setButtonDisabled(false);
            });
        }).catch(err => {
            notify('error', 'Dữ liệu chưa hợp lệ, vui lòng kiểm tra lại form')
        })
    }

    useEffect(() => {
        if (open && initialValues){

            form.resetFields();
            if (initialValues){
                form.setFieldsValue(initialValues);
            }
        }
    }, [initialValues]);
    const DrawerClose = () => {
        form.resetFields();
        onClose();
    }

    return (
        <Drawer
            title={title}
            placement="right"
            onClose={DrawerClose}
            open={open}
            width={500}
            extra={
                <Space>
                    <Button disabled={buttonDisabled} danger onClick={formSubmit} icon={<SaveOutlined />}>
                        Lưu thông tin
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
            >
                {
                    formSchema.map((item, index) => {
                        if (item.hidden || (item.hiddenWhenHasId && initialValues?._id))
                            return false;

                        return (
                            <Form.Item
                                key={index}
                                label={item.title}
                                name={item.dataIndex}
                                rules={item.rules}
                            >
                                {item?.control ? item.control : <Input disabled={item?.readonly}/>}
                            </Form.Item>
                        )
                    }).filter(Boolean)
                }
                <Form.Item
                    name="_id"
                >
                    <Input style={{display: 'none'}}/>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default InputDrawer;