import toast from 'react-hot-toast';

export const notify = (type, message, duration = 3) => {
    let toastConfig = {
        duration: duration*1000,
        position: 'top-right',
        closable: true,
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            padding: '20px'
        },
    }

    switch (type){
        case "error":
            toast.error(message, toastConfig);
            break;
        case "success":
            toast.success(message, toastConfig);
            break;
        default:
            toast.success(message, toastConfig);
    }
}