import {useUserInfo} from "../../Helpers/user-session-helper";
import {Button, Card} from "antd";
import {JsonView, darkStyles} from "react-json-view-lite";
import {useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import 'react-json-view-lite/dist/index.css';
import {ReloadOutlined} from "@ant-design/icons";


const LogView = () => {
    const [data, setData] = useState([]);

    const load = () => {
        api.get('/info/server/log/get').then((response) => {
            if (response) {
                setData(response);
            }
        })
    }

    useEffect(() => {
        load()
    }, []);

    //get userInfo
    const userInfo = useUserInfo.getState();
    //if userInfo is null, undefined or userInfo.level < 4096, return
    if (!userInfo || userInfo.level < 4096) return (<><h3>You're not allowed</h3></>);

    return (
        <Card
            title="LOGS"
            extra={<Button onClick={load} icon={<ReloadOutlined />} size="small"/>}
        >
            <JsonView
                data={data}
                style={darkStyles}
            />
        </Card>
    )

}
export default LogView