import {Select, Space} from "antd";
import {useEffect, useState} from "react";

const {Option} = Select;

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export function isIterable(obj) {
    return obj != null && typeof obj[Symbol.iterator] === 'function';
}
export function isValidDate(dateString) {
    try {
        // Parse the date string into day, month, and year components
        const dateParts = dateString.split('-');
        if (dateParts.length !== 3) {
            return false; // Incorrect format, should have 3 parts
        }

        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);

        // Check if day, month, and year are valid
        if (
            isNaN(day) || isNaN(month) || isNaN(year) ||
            day < 1 || day > 31 || month < 1 || month > 12 || year < 1000
        ) {
            return false;
        }

        // Check for leap years and valid days in February
        if (month === 2) {
            if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                return day <= 29;
            } else {
                return day <= 28;
            }
        }

        // Check for valid days in months with 30 or 31 days
        if ((month === 4 || month === 6 || month === 9 || month === 11)) {
            return day <= 30;
        }
    } catch (err) {
        return false;
    }

    return true; // If all checks pass, it's a valid date
}

export const OptionRender = ({item, index, options}) => {
    return (
            <Space direction="vertical" size="small" key={index}>
                <Space direction="horizontal">
                    {options.logo ? <img src={item[options?.logo]} style={{height: '30px'}}/> : null}
                    <span className="text-s text-muted text-bold"
                          title={item[options?.label]}>{item[options?.label]}</span>
                </Space>
                {options?.description && (
                    <span className="text-s text-muted" title={item[options.description]}>
                    {item[options.description]}
                </span>
                )}
            </Space>
        /*</Option>*/
    )
}

export const OptionsWithDescription = (items, options) => {

    if (!items || !isIterable(items)) {
        items = [];
    }
    return items.map((item, index) => {
        return (
            <Option key={index} value={item[options.value] || item[options?.label]}
                label={item[options?.label] || item[options.value]}>
                {OptionRender({item, index, options})}
            </Option>
        )
    })
}

export function ExpandableText({ text, maxLength = 12}) {
    //if text is empty after trim, return null

    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    if (!text?.trim()) return <span>-</span>;

    const displayText = expanded ? text : `${text.slice(0, maxLength)}...`;

    return (
        <div>
            <span onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                {displayText}
            </span>
        </div>
    );
}

export const processTableColumns = (view, order, reference, set) => {
    //get current table columns from localStorage
    //view: tableColumns, order: tableColumnsOrder
    //reference: Original (mutated) table column data, set: setTableColumns

    let requestTableView = null;
    let tableViewOrder = null;
    try {
        requestTableView = JSON.parse(localStorage.getItem(view));
        tableViewOrder = JSON.parse(localStorage.getItem(order));
    } catch (err) {
        console.log('Error parsing localStorage', err)
    }

    //if localStorage has table columns, set it to tableColumnsIndex
    if (requestTableView) {
        if (tableViewOrder) {
            const sortedTableView = tableViewOrder.map((item, index) => {
                return {...reference.find((column) => column.dataIndex === item.dataIndex), hidden: item.hidden}
            });

            //console.log("Changing view", sortedTableView)
            set(sortedTableView)
        } else {
            //unordered table view, only check for hidden property (unchecked columns)
            const checkedColumns = requestTableView.map((item) => {
                return {...reference.find((column) => column.dataIndex === item.dataIndex), hidden: item.hidden}
            });

            set(checkedColumns);
        }
    } else {
        //if localStorage doesn't have table columns, set default table columns to columns
        set(reference);
    }
}

export const calculateForeignToVND = (value, rate) => {
    //if value is null or blank, set to 0, if string, remove comma
    value = value || 0;
    value = typeof value === 'string' ? value.replace(/,/g, '') : value;
    //convert to number
    value = Number(value);
    //if rate is null or blank, set to 0, if string, remove comma
    rate = rate || 0;
    rate = typeof rate === 'string' ? rate.replace(/,/g, '') : rate;
    //convert to number
    rate = Number(rate);
    //calculate converted amount, with only 2 decimal places
    return parseInt((value * rate).toFixed(0));
}

export const numberFormat = (value, min = 0) => {
    if (value === null || value === undefined || value === '') {
        value = min;
    }

    const [integerPart, decimalPart] = `${value}`.split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalPart) {
        return `${formattedIntegerPart}.${decimalPart}`;
    } else {
        return formattedIntegerPart;
    }
}

export const parseNumber = (value) => {
    if (value === null || value === undefined || value === '') {
        return 0;
    }

    return parseInt(value.toString().replace(/\D/g, ''))

}