import {useEffect, useRef, useState} from "react";
import api from "../../Helpers/api-helper";
import {Avatar, Button, Card, Drawer, Input, List, Popover, Space, Table, Tag} from "antd";
import {DeleteOutlined, HomeOutlined, PlusOutlined, SaveOutlined, UserAddOutlined} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import Search from "antd/es/input/Search";
import {UseLoading} from "../../Helpers/loading-spinner-helper";

const Permissions = ({open, onClose, department, onOk}) => {
    const [inputOpen, setInputOpen] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (department) {
            setSaveLoading(true);
            api.post('/master/department/get/permission', {_id: department._id}).then((response) => {
                if (response) {
                    setPermissionList(response.admin);
                }
            }).finally(() => {
                setSaveLoading(false);
            })
        }
    }, [department]);

    const handleClose = () => {
        setPermissionList([])
        setSaveLoading(false)
        onClose();
    }

    const InputBox = ({open, setOpen}) => {
        const inputRef = useRef(null);
        const [uid, setUid] = useState(null);
        const [loading, setLoading] = useState(false);
        const handleAdd = () => {
            //Check if uid is exist in permissionList
            if (permissionList?.find((item) => item.code === Number(uid))) {
                notify('error', 'Nhân viên này đã có quyền xem đề nghị');
                return;
            }

            setLoading(true);
            api.post('/master/user/get', {uid}).then((response) => {
                if (response) {
                    if (response?.error === 'Not exist!') {
                        notify('error', 'Nhân viên này không tồn tại');
                        return;
                    }
                    //check in existing list, match by code, if not found, add to list
                    const found = permissionList?.find((item) => item.code === response.code);
                    if (!found) {
                        setPermissionList([...(permissionList ? permissionList : []), response]);
                    } else {
                        notify('error', 'Nhân viên này đã có quyền xem đề nghị')
                    }
                    setOpen(false);

                }
            }).finally(() => {
                setLoading(false);
            })
        }

        useEffect(() => {
            if (!open) {
                setUid(null);
                setLoading(false)
            }

            if (open) {
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 100);
            }
        }, [open]);

        return (
            <Input
                ref={inputRef}
                placeholder="Mã nhân viên"
                style={{maxWidth: 200}}
                prefix={<UserAddOutlined/>}
                suffix={<Button loading={loading} size="small" type="text" onClick={handleAdd}>Thêm</Button>}
                onPressEnter={handleAdd}
                onChange={(e) => setUid(e.target.value)}
                value={uid}
                autoFocus={true}
                allowClear={true}
            />
        )
    }

    const handleSave = () => {
        //Save permissionList to department

        //Set payload to have only code, fullname, department, position
        const payload = permissionList.map((item) => {
            return {
                code: Number(item.code),
                fullname: item.fullname,
                department: item.department,
                position: item.position,
            }
        });

        setSaveLoading(true);

        api.post('/master/department/set/permission', {_id: department._id, admin: payload}).then((response) => {
            if (response) {
                notify('success', response);
                onOk();
            }
        }).finally(() => {
            setSaveLoading(false);
        });
    }

    const handleDelete = (code) => {
        //Delete matching item with code
        const newList = permissionList.filter((item) => item.code !== code);
        setPermissionList(newList);
    }


    return (
        <Drawer
            open={open}
            width={500}
            onClose={handleClose}
            title="Phân quyền xem đề nghị"
            extra={<Button icon={<SaveOutlined/>} loading={saveLoading} type="text" title="Lưu thông tin" danger
                           onClick={handleSave}/>}
        >
            <div className="text-bold mb-3"><HomeOutlined/> {department?.title}</div>
            <Card
                size="small"
                title="Danh sách quản lý"
                extra={
                    <Space>
                        <Popover open={inputOpen} content={<InputBox open={inputOpen} setOpen={setInputOpen}/>}
                                 arrow={false} placement="bottomRight" trigger="click" onOpenChange={setInputOpen}>
                            <Button type="text" size="small" icon={<PlusOutlined/>}/>
                        </Popover>
                    </Space>
                }
            >
                <List
                    size="small"
                    dataSource={permissionList}
                    renderItem={(item) => {
                        return (
                            <List.Item
                                extra={<Button size="small" type="text" danger icon={<DeleteOutlined/>}
                                               title="Xóa người dùng" onClick={() => handleDelete(item.code)}/>}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            style={{
                                                backgroundColor: '#7265e6',
                                                verticalAlign: 'middle',
                                            }}
                                            size="small"
                                            gap={2}
                                        >
                                            {/* Get first letter of last word in the item.fullname string */}
                                            {item.fullname.split(' ').pop().charAt(0)}
                                        </Avatar>
                                    }
                                    title={`${item.fullname} (${item.code})`}
                                    description={(
                                        <>
                                            <span>{item.department}</span>
                                            <br/>
                                            <span>{item.position}</span>
                                        </>
                                    )}
                                />
                            </List.Item>
                        )
                    }}
                />
            </Card>
        </Drawer>
    )
}
const Departments = () => {
    const [departments, setDepartments] = useState([]);
    const [_departments, _setDepartments] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const setLoading = UseLoading().setLoading;

    const columns = [
        {
            title: 'Tên phòng ban',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return (
                    <span className="link-text" onClick={() => {
                        setSelectedDepartment(record);
                        setDrawerOpen(true);
                    }}>{text}</span>
                )
            }
        },
        {
            title: 'Quản lý',
            dataIndex: 'admin',
            sorter: (a, b) => !!a?.admin,
            render: (text, record) => {
                return (
                    <>
                        {record?.admin?.map((item) => (
                            <Tag color="blue" key={item.fullname}>
                                {item.fullname}
                            </Tag>
                    ))}
                    </>
                );
            }

        }]

    const fetchDepartments = () => {
        setLoading(true)
        api.get('/master/department/list').then((response) => {
            if (response) {
                //add key to each item
                response.forEach((item, index) => {
                    item.key = index;
                });

                setDepartments(response)
                _setDepartments(response);
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchDepartments()
    }, []);

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedDepartment(null);
    }

    const onSearch = value => {
        const reg = new RegExp(value, "gi");
        const filteredData = _departments.map(record => {
            const titleMatch = record.title.match(reg);
            if (!titleMatch) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        setDepartments(filteredData)
    }

    return (
        <div>
            <div className="items-start-end">
                <div className="text-l text-bold mb-2">Phòng ban</div>
                <div>
                    <Search
                        size="small"
                        placeholder="Tìm kiếm phòng ban"
                        onSearch={onSearch}
                        allowClear={true}
                        onChange={(e) => {
                            if (e.target.value.trim !== '') {
                                onSearch(e.target.value)
                            }
                        }}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={departments}
            />
            <Permissions open={drawerOpen} onClose={handleDrawerClose} department={selectedDepartment} onOk={fetchDepartments}/>
        </div>
    )
}

export default Departments;