import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CopyOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useState} from "react";

const Clipboard = ({text, timeout = 3}) => {
    const [copied, setCopied] = useState(false);

    const onCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, timeout);
    }

    return (
        <CopyToClipboard text={text} onCopy={onCopy}>
            {text}
            <Button icon={<CopyOutlined/>} type="link" size="small" onClick={e => e.preventDefault()}/>
        </CopyToClipboard>
    )
}

export default Clipboard