import {Col, DatePicker, Form, Input, Row, Select} from "antd";
import {sanitizeData, useTravelExpenseStore} from "../stores";
import {IconBuilding, IconBuildingBank, IconCreditCard, IconGeoLocation, IconNote} from "../../Icons";
import {useEffect} from "react";
import {OptionRender} from "../../../Helpers/common-helper";

const FormMetaData = () => {
    const colGutter = 12;
    const [form] = Form.useForm();

    const {personnel, setPaymentRequestMeta} = useTravelExpenseStore();
    const colSpans = {
        xs: 24,
        sm: 12,
        md: 12
    }

    const handlePersonnelChange = (value) => {
        const selectedPerson = personnel.find(person => person.fullname === value);
        form.setFieldsValue({
            department: selectedPerson.department
        })

        handleChange()
    }

    const handleChange = () => {
        const formValues = sanitizeData(form.getFieldsValue())
        setPaymentRequestMeta(formValues)
    }

    useEffect(() => {
        return () => {
            form.resetFields();
        }
    }, []);

    return (
        <div>
        <Form
            layout="vertical"
            form={form}
            className="travel-expense-body-content"
        >
            <Row gutter={colGutter}>
                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="requester"
                        label="Người đề nghị"
                        className="narrow-form-item"
                    >
                        <Select
                            placeholder="Chọn người đề nghị"
                            options={personnel.map(person => ({
                                label: person.fullname,
                                value: person.fullname
                            }))}
                            onChange={handlePersonnelChange}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="department"
                        label="Phòng ban"
                        className="narrow-form-item"
                    >
                        <Input
                            prefix={<IconBuilding/>}
                            disabled
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="accountNumber"
                        label="Số tài khoản"
                        className="narrow-form-item"
                    >
                        <Input
                            prefix={<IconCreditCard/>}
                            placeholder="Số tài khoản nhận tiền"
                        />
                    </Form.Item>
                </Col>

                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="bank"
                        label="Ngân hàng"
                        className="narrow-form-item"
                    >
                        <Input
                            prefix={<IconBuildingBank/>}
                            placeholder="Tại Ngân hàng"
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>

                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="description"
                        label="Nội dung thanh toán"
                        className="narrow-form-item"
                    >
                        <Input
                            prefix={<IconNote/>}
                            placeholder="Nội dung thanh toán"
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>

                <Col
                    {...colSpans}
                >
                    <Form.Item
                        name="destination"
                        label="Địa điểm công tác"
                        className="narrow-form-item"
                    >
                        <Input
                            prefix={<IconGeoLocation/>}
                            placeholder="Địa điểm công tác"
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <Form.Item
                        name="fromDate"
                        label="Từ ngày"
                        className="narrow-form-item"
                    >
                        <DatePicker
                            placeholder="Từ ngày"
                            style={{width: '100%'}}
                            format="DD/MM/YYYY"
                            onChange={handleChange}
                        />
                    </Form.Item>
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <Form.Item
                        name="toDate"
                        label="Đến ngày"
                        className="narrow-form-item"
                    >
                        <DatePicker
                            placeholder="Đến ngày"
                            style={{width: '100%'}}
                            format="DD/MM/YYYY"
                            onChange={handleChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>


        </div>

    )
}

export default FormMetaData;