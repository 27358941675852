import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts.css';
import './index.css';
import App from './App';
import {ConfigProvider} from "antd";
import {Toaster} from "react-hot-toast";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
    <>
        <div><Toaster/></div>
        <ConfigProvider
            theme={{
                token: {
                    screenLGMin: 1000,
                    screenLG: 1000,
                    borderRadius: '10px',
                    controlHeight: 34,
                    fontFamily: 'Google Sans,Roboto,Arial,sans-serif; !important',
                    colorPrimary: '#5f81ff',
                    colorError: '#F2479B',
                },
            }}
        >
            <App/>
        </ConfigProvider>
    </>
    //</React.StrictMode>
);

