import {
    App,
    Button,
    Flex, Space, Table

} from "antd";
import { useRowSelection, useTravelExpenseStore} from "../stores";
import {
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    PlusOutlined,
} from "@ant-design/icons";
import {useEffect, useState} from "react";
import ExpenseLineForm from "./ExpenseLineForm";
import ExpenseLineDisplay from "./ExpenseDisplay";
import {testData} from "./helper";

const Step2DetailSheet = ({activeStep}) => {
    const thisStep = 'detailSheet';

    const {modal} = App.useApp();

    const [inputFormOpen, setInputFormOpen] = useState(false);
    const [editingLineIndex, setEditingLineIndex] = useState(null);

    const {detailSheet, deleteDetailSheetLine} = useTravelExpenseStore();

    const handleEdit = (index) => {
        //e.stopPropagation();

        handleInputFormOpen(true);
        setEditingLineIndex(index);
    }

    const handleDelete = (index) => {
        //e.stopPropagation();

        modal.confirm({
            title: 'Xoá dữ liệu',
            icon: <ExclamationCircleFilled/>,
            content: (<span>Có chắc chắn xoá dữ liệu này không?</span>),
            okText: 'Đồng ý xoá',
            cancelText: 'Bỏ qua',
            okType: 'danger',
            okButtonProps: {
                icon: <DeleteOutlined/>
            },
            cancelButtonProps: {
                icon: <CloseCircleOutlined/>
            },
            onOk: () => {
                deleteDetailSheetLine(index)
            },
            onCancel() {
            },
        });

    }

    const handleInputFormOpen = (isEditing = false) => {
        setInputFormOpen(true);

        if (!isEditing) {
            setEditingLineIndex(null);
        }
    }

    const columns = [
        {
            title: 'Mã NV',
            dataIndex: 'uid',
            key: 'uid',
            responsive: ['lg'],
            //width: breakpoints.lg ? '10%' : breakpoints.md ? '10%' : '20%'
            render: (text) => <span style={{whiteSpace: 'nowrap'}}>{text}</span>,
        },
        {
            title: 'Họ tên',
            dataIndex: 'fullname',
            key: 'fullname',
            //width: breakpoints.lg ? '45%' : '55%',
            render: (text, record, index) => <span style={{whiteSpace: 'nowrap', color: !testData(detailSheet[index]) ? 'orange': 'inherit'}}>{text}</span>,
        },
        {
            title: 'Ngày',
            dataIndex: 'travelDate',
            key: 'travelDate',
            align: 'center',
            //width: breakpoints.lg ? '30%' : '25%',
            render: (text) => <span style={{whiteSpace: 'nowrap'}}>{text?.format("DD/MM/YY")}</span>,
        },
    ]

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const TableFooter = ({rowSelected}) => {
        return (
            <Flex
                justify="space-between"
                align="start"
                className="mt-2"
            >
                <div>Tổng cộng:</div>
                <Space>
                    <Button
                        icon={<DeleteOutlined/>}
                        size="small"
                        type="text"
                        disabled={!rowSelected?.length > 0}
                        className="me-3"
                        danger
                        onClick={() => {
                            const itemIndex = detailSheet.findIndex(item => item.key === rowSelected[0])
                            handleDelete(itemIndex)
                        }}
                    />
                    <Button
                        icon={<EditOutlined/>}
                        disabled={!rowSelected?.length > 0}
                        type="link"
                        //Get the detailSheet item index from the selected row key by matching key prop
                        onClick={() => handleEdit(detailSheet.findIndex(item => item.key === rowSelected[0]))}
                    />
                    <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        size="small"
                        onClick={() => handleInputFormOpen()}
                    />
                </Space>
            </Flex>
        )
    }

    const [selectedRowKeys, setSelectedRowKeys, rowSelection] = useRowSelection();

    const onRowClick = (record, rowIndex) => {
        setSelectedRowKeys(prevKeys => { // Use callback to access latest state
            if (prevKeys.includes(record.key)) {
                return [];
            } else {
                return [record.key];
            }
        });
    }

    useEffect(() => {
        setSelectedRowKeys([]);
        setExpandedRowKeys([])
    }, [activeStep]);

    return (
        <div style={{marginBottom: '50px'}}>
            <ExpenseLineForm
                open={inputFormOpen}
                close={setInputFormOpen}
                expense={editingLineIndex}
                done={() => setEditingLineIndex(null)}
            />
            <Table
                columns={columns}
                dataSource={detailSheet}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 6,
                }}
                width={300}
                size="small"
                scroll={{y: 273, x: 390, scrollToFirstRowOnChange: true}}
                footer={() => <TableFooter rowSelected={selectedRowKeys}/>}
                className="mb-4"
                rowSelection={rowSelection}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => onRowClick(record, rowIndex), // click row
                }}}
                expandable={{
                    expandedRowRender: (item) => (<ExpenseLineDisplay expense={item}/>),
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedRows) => {
                        setExpandedRowKeys(expandedRows);
                    }
                }}
            />
            {/*}
            <Collapse
                style={{overflowY: 'auto', maxHeight: '350px'}}
                items={
                    detailSheet.map((item, index) => {
                        return {
                            key: index,
                            label: (
                                <span>{item.fullname}{displayData(item.travelDate?.format('DD/MM'), '-')}{displayData(item?.departure, 'từ')}{displayData(item?.destination, 'đến')}</span>),
                            children: <ExpenseLineDisplay expense={item}/>,
                            extra: (
                                <div>
                                    <Flex>
                                        {testData(detailSheet[index]) ? null :
                                            <Tooltip
                                                title="Dữ liệu chưa đầy đủ"
                                                arrow={false}
                                            >
                                                <ExclamationCircleOutlined className="text-danger"/>
                                            </Tooltip>
                                        }
                                        <ExtraButton itemIndex={index} onEdit={handleEdit} onDelete={handleDelete}/>
                                    </Flex>
                                </div>
                            )
                        }
                    })
                }
            />
            */}
        </div>
    )
}

export default Step2DetailSheet;