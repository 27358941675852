import {CloseOutlined} from "@ant-design/icons";
import {Button, Descriptions, Modal} from "antd";
import {formatNumber} from "../../Helpers/number-helper";
import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */

const PaymentView = ({open, onClose, paymentData}) => {
    const items = [
        {
            key: '1',
            label: 'Mã thanh toán',
            children: paymentData?.request_code
        },
        {
            key: '2',
            label: 'Cấp nguồn bởi',
            children: paymentData?.created_by_name
        },
        {
            key: '3',
            label: 'Số tiền',
            children: formatNumber(paymentData?.amount)
        },
        {
            key: '4',
            label: 'Loại tiền',
            children: paymentData?.currency_code
        },
        {
            key: '5',
            label: 'Đơn vị/Người thụ hưởng',
            children: paymentData?.receiver_name,
        },
        {
            key: '6',
            label: 'Diễn giải',
            children: paymentData?.description,
        },
        {
            key: '7',
            label: 'Đi tiền bởi',
            children: paymentData?.money_transfer_by_name,
        },
        {
            key: '8',
            label: 'Ngày đi tiền',
            children: paymentData?.money_transfer_at ? dayjs(paymentData?.money_transfer_at).format('DD-MM-YYYY HH:mm') : '',
        },
    ]

    return(
        <Modal
            title="Xem thông tin thanh toán"
            centered
            width={700}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            footer={<Button type="primary" onClick={onClose} icon={<CloseOutlined />}>Đóng lại</Button>}
        >
            <Descriptions
                className="mt-3"
                size="middle"
                layout="vertical"
                bordered
                items={items}
                column={2}
            />
        </Modal>
    )
}

export default PaymentView