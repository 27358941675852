import {Form, Modal, DatePicker, Select, Button, Space, Row, Col, InputNumber, Checkbox} from "antd";
import {useEffect} from "react";
import {UseSiteConfig} from "../../Helpers/config-helper";
import {OptionsWithDescription} from "../../Helpers/common-helper";
import {ClearOutlined} from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {IconMultiCurrency} from "../Icons";

import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */

dayjs.extend(customParseFormat);


const PaymentListFilter = ({open, onClose, setFilter, onReload}) => {
    const [form] = Form.useForm();

    //const [filter, setFilter] = useState(null)
    const siteConfig = UseSiteConfig(state => state.siteConfig);

    useEffect(() => {
        if (open) {
            //get filter from localStorage, and validate it, if it's valid (can be parsed as JSON), set it to filter state
            let payment_filter = null;
            try {
                payment_filter = JSON.parse(localStorage.getItem('payment_filter'));
                //convert date string to dayjs object
                if (payment_filter?.payment_date)
                    payment_filter.payment_date = payment_filter.payment_date?.map((date) => dayjs(date, 'YYYY-MM-DD'));

            } catch (err) {
            }

            if (payment_filter) {
                setFilter(payment_filter);
                form.setFieldsValue(payment_filter);
            }
        }
    }, [open]);
    const handleClose = () => {
        onClose();
    }

    const handleOk = () => {
        form.validateFields().then((values) => {
            //filter out null value
            values = Object.entries(values).reduce((acc, [key, value]) => {
                if (value !== null && value !== undefined) {
                    if (key === 'payment_date')
                        value = value.map((date) => date.format('YYYY-MM-DD'));

                    acc[key] = value;

                }
                return acc;
            }, {});

            //save filter to localStorage
            localStorage.setItem('payment_filter', JSON.stringify(values));

            //console.log(JSON.stringify(values))

            //set filter state
            setFilter(values);
            onReload();
            //close modal
            handleClose();
        }).catch((err) => {
            console.log(err)
        })
    }

    const clearFilter = () => {
        form.resetFields();
        localStorage.removeItem('payment_filter');
        setFilter({});
    }

    return (
        <Modal
            title="Lọc dữ liệu"
            centered={true}
            open={open}
            onCancel={handleClose}
            onOk={handleOk}
            footer={(
                <div className="items-start-end">
                    <Button icon={<ClearOutlined/>} onClick={clearFilter}>Xóa bộ lọc</Button>
                    <Space>
                        <Button onClick={handleClose}>Bỏ qua</Button>
                        <Button onClick={handleOk} type="primary">Lọc dữ liệu</Button>
                    </Space>
                </div>
            )}
        >
            <Form
                form={form}
                className="mt-3"
                layout="vertical"
            >
                <Form.Item
                    label="Tài khoản đi tiền"
                    name="transfer_from_account"
                >
                    <Select
                        allowClear={true}
                        style={{width: '100%'}}
                        optionLabelProp="name"
                        mode="multiple"
                    >
                        <Select.Option value="0" name="Tất cả" key="-1">Tất cả</Select.Option>
                        {OptionsWithDescription(siteConfig?.transfer_from_account, {
                            label: 'name',
                            description: 'description'
                        })}
                        {/*
                            siteConfig?.transfer_from_account?.map((item, index) => {
                                return (
                                    <Select.Option key={index} value={item?.name} name={item?.name}>
                                        {item?.name}
                                    </Select.Option>
                                )
                            })
                          */}
                    </Select>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày TC xác nhận"
                            name="payment_date"
                        >
                            <DatePicker.RangePicker
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Kế toán duyệt"
                            name="accountant_id"
                        >
                            <Select
                                allowClear={true}
                                style={{width: '100%'}}
                                optionLabelProp="label"
                            >
                                <Select.Option value="0" label="Tất cả" key="-1">Tất cả</Select.Option>
                                {OptionsWithDescription(siteConfig?.personnel, {
                                    label: 'fullname',
                                    value: 'uid',
                                    description: 'position_title'
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Loại chi phí"
                    name="expense_type"
                >
                    <Select
                        allowClear={true}
                        optionLabelProp="label"
                    >
                        <Select.Option value="0" label="Tất cả" key="-1">Tất cả</Select.Option>
                        {OptionsWithDescription(siteConfig?.expense_type, {
                            label: 'name',
                            description: 'description'
                        })}
                    </Select>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Loại tiền tệ"
                            name="currency_code"
                        >
                            <Select
                                allowClear={true}
                                style={{width: '100%'}}
                                optionLabelProp="name"
                                mode="multiple"
                            >
                                <Select.Option value="0" name="Tất cả" key="-1">Tất cả</Select.Option>
                                {OptionsWithDescription(siteConfig?.rates, {
                                    label: 'currency',
                                    description: 'currency_name'
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Tình trạng"
                            name="transfer_status"
                        >
                            <Select
                                allowClear={true}
                            >
                                <Select.Option value="-1">Tất cả</Select.Option>
                                <Select.Option value="0">Chưa đi tiền</Select.Option>
                                <Select.Option value="1">Đã đi tiền</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Số tiền cần chi tối thiểu"
                            name="min_amount"
                        >
                            <InputNumber
                                defaultValue={null}
                                style={{width: '100%'}}
                                addonBefore=<IconMultiCurrency />
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value) => {
                            //if value is null or blank, set to null
                            if (!value) {
                                value = null;
                            }
                            //setFilter({...filter, min_amount: value})
                        }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số tiền cần chi tối đa"
                            name="max_amount"
                        >
                            <InputNumber
                                defaultValue={null}
                                style={{width: '100%'}}
                                addonBefore=<IconMultiCurrency />
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(value) => {
                            //if value is null or blank, set to null
                            if (!value) {
                                value = null;
                            }
                            //setFilter({...filter, max_amount: value})
                        }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default PaymentListFilter