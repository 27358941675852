import {AutoComplete, Col, Form, Input, InputNumber, Row, Space} from "antd";
import {IconFood, IconSleeping, IconTotalExpense} from "../../../../Icons";
import {processFormValues, sanitizeData, useRequestPaymentLineStore} from "../../../stores";
import {useEffect} from "react";
import {numberFormat} from "../../../../../Helpers/common-helper";

export const LivingExpense = ({days}) => {
    const [form] = Form.useForm();

    const iconSize = {
        width: 18,
        height: 18
    }

    const meal_allowance = [{
        value: '100000',
        label: '100,000 đ'
    }, {
        value: '150000',
        label: '150,000 đ'
    },{
        value: '180000',
        label: '180,000 đ'
    }, {
        value: '200000',
        label: '200,000 đ'
    }, {
        value: '250000',
        label: '250,000 đ'
    }, {
        value: '300000',
        label: '300,000 đ'
    }, {
        value: '400000',
        label: '400,000 đ'
    }]

    const lodging_allowance = [{
        value: '350000',
        label: '350,000 đ'
    }, {
        value: '400000',
        label: '400,000 đ'
    }, {
        value: '500000',
        label: '500,000 đ'
    }, {
        value: '600000',
        label: '600,000 đ'
    }, {
        value: '700000',
        label: '700,000 đ'
    }, {
        value: '800000',
        label: '800,000 đ'
    }]


    const numberStyle = {
        color: 'blue'
    }

    const fieldsOptions = [
        ['allowance_quota', 'allowance_days', 'allowance_total'],
        ['accommodation_quota', 'accommodation_days', 'accommodation_total']
    ]

    const {line, setData} = useRequestPaymentLineStore();

    const handleChange = () => {
        const fields = processFormValues(form, fieldsOptions)

        //Calculate total for each field
        const allowance_total = fields.allowance_total ? fields.allowance_total : 0
        const accommodation_total = fields.accommodation_total ? fields.accommodation_total : 0

        form.setFieldValue('allowance_grand_total', numberFormat(allowance_total+ accommodation_total))
        setData(form.getFieldValue());
    }

    useEffect(() => {
        //Set form allowance_days and accommodation_days with days props' value if days is not null
        if (days) {
            form.setFieldsValue({
                allowance_days: days,
                accommodation_days: days
            })
        }

        handleChange()
    }, [days])

    useEffect(() => {
        if (line.length === 0){
            form.resetFields();
        } else {
            form.setFieldsValue(line)
        }
    }, [line])

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Space className="mb-2">
                <IconFood style={{...iconSize}}/>
                <span>Phí sinh hoạt</span>
            </Space>

            <Row gutter={4}>
                <Col span={10}>
                    <Form.Item
                        name="allowance_quota"
                        label="Đinh mức"
                    >
                        <AutoComplete
                            options={meal_allowance}
                        >
                            <Input
                                onBlur={handleChange}
                                onPressEnter={handleChange}
                                allowClear
                            />
                        </AutoComplete>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        name="allowance_days"
                        label="Số ngày"
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            min={1}
                            onPressEnter={handleChange}
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="allowance_total"
                        label="Thành tiền"
                    >
                        <Input
                            style={{width: '100%', ...numberStyle}}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space className="mb-2">
                <IconSleeping style={{...iconSize}}/>
                <span>Phí lưu trú</span>
            </Space>
            <Row gutter={4}>
                <Col span={10}>
                    <Form.Item
                        name="accommodation_quota"
                        label="Đinh mức"
                    >
                        <AutoComplete
                            options={lodging_allowance}
                        >
                            <Input
                                onBlur={handleChange}
                                onPressEnter={handleChange}
                                allowClear
                            />
                        </AutoComplete>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        name="accommodation_days"
                        label="Số ngày"
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            min={1}
                            onBlur={handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="accommodation_total"
                        label="Thành tiền"
                    >
                        <Input
                            style={{width: '100%', ...numberStyle}}
                            onPressEnter={handleChange}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="allowance_grand_total"
                className="text-red text-bold"
                style={{
                    color: 'red',
                    fontWeight: 'bold'

                }}
            >
                <Input
                    status="error"
                    prefix={<IconTotalExpense/>}
                    addonBefore="Tổng cộng"
                    readOnly

                />
            </Form.Item>
        </Form>
    )
}