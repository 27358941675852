import { Grid, Tabs} from "antd";
import {formSteps} from "./stores";

const FormSteps = ({currentStep, setStep}) => {

    const breakpoints = Grid.useBreakpoint();

    const handleChange = (key) => {
        setStep(key);
    }

    return (
        <Tabs
            defaultActiveKey="1"
            activeKey={currentStep || "1"}
            onChange={handleChange}
            items={formSteps.map(step => ({
                key: step.key,
                label: !breakpoints.md ? `` : `${step.key}. ${step.label}`,
                icon: <span style={!breakpoints.md ? {fontSize: '18px'} : {}}>{step.icon}</span>,
                children: step.children
            }))}
        />
    )

    /*
        return (
            <Flex align="baseline" justify="space-between">
                <span>Bước hiện tại</span>
                <Select
                    style={{width: '230px'}}
                    options={formSteps}
                    variant='inline'
                    optionRender={(option) => (
                        <Space>
                            {option.data.icon}
                            {option.data.label}
                        </Space>
                    )}
                    labelRender={(option, data) => {
                        //Get icon prop from formSteps match key = option.key
                        const icon = formSteps.find(item => item.key === option.key).icon;
                        return (
                            <Space>
                                {icon}
                                {(<strong>{option.label}</strong>)}
                            </Space>
                        )
                    }}
                    onChange={handleChange}
                    defaultValue={formSteps[0].value}
                    value={currentStep}
                />
            </Flex>
        )
     */
}

export default FormSteps;