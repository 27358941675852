export const provinces = [
    {
        "id": "89",
        "name": "An Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "ANGIANG"
    },
    {
        "id": "77",
        "name": "Bà Rịa - Vũng Tàu",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BARIAVUNGTAU"
    },
    {
        "id": "24",
        "name": "Bắc Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BACGIANG"
    },
    {
        "id": "6",
        "name": "Bắc Kạn",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BACKAN"
    },
    {
        "id": "95",
        "name": "Bạc Liêu",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BACLIEU"
    },
    {
        "id": "27",
        "name": "Bắc Ninh",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BACNINH"
    },
    {
        "id": "83",
        "name": "Bến Tre",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BENTRE"
    },
    {
        "id": "52",
        "name": "Bình Định",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BINHDINH"
    },
    {
        "id": "74",
        "name": "Bình Dương",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BINHDUONG"
    },
    {
        "id": "70",
        "name": "Bình Phước",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BINHPHUOC"
    },
    {
        "id": "60",
        "name": "Bình Thuận",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "BINHTHUAN"
    },
    {
        "id": "96",
        "name": "Cà Mau",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "CAMAU"
    },
    {
        "id": "92",
        "name": "Cần Thơ",
        "type": 1,
        "typeText": "Trung ương",
        "slug": "CANTHO"
    },
    {
        "id": "4",
        "name": "Cao Bằng",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "CAOBANG"
    },
    {
        "id": "48",
        "name": "Đà Nẵng",
        "type": 1,
        "typeText": "Trung ương",
        "slug": "DANANG"
    },
    {
        "id": "66",
        "name": "Đắk Lắk",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "DAKLAK"
    },
    {
        "id": "67",
        "name": "Đắk Nông",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "DAKNONG"
    },
    {
        "id": "11",
        "name": "Điện Biên",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "DIENBIEN"
    },
    {
        "id": "75",
        "name": "Đồng Nai",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "DONGNAI"
    },
    {
        "id": "87",
        "name": "Đồng Tháp",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "DONGTHAP"
    },
    {
        "id": "64",
        "name": "Gia Lai",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "GIALAI"
    },
    {
        "id": "2",
        "name": "Hà Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HAGIANG"
    },
    {
        "id": "35",
        "name": "Hà Nam",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HANAM"
    },
    {
        "id": "1",
        "name": "Hà Nội",
        "type": 1,
        "typeText": "Trung ương",
        "slug": "HANOI"
    },
    {
        "id": "42",
        "name": "Hà Tĩnh",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HATINH"
    },
    {
        "id": "30",
        "name": "Hải Dương",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HAIDUONG"
    },
    {
        "id": "31",
        "name": "Hải Phòng",
        "type": 1,
        "typeText": "Trung ương",
        "slug": "HAIPHONG"
    },
    {
        "id": "93",
        "name": "Hậu Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HAUGIANG"
    },
    {
        "id": "79",
        "name": "Hồ Chí Minh",
        "type": 1,
        "typeText": "Trung ương",
        "slug": "HOCHIMINH"
    },
    {
        "id": "17",
        "name": "Hoà Bình",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HOABINH"
    },
    {
        "id": "33",
        "name": "Hưng Yên",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "HUNGYEN"
    },
    {
        "id": "56",
        "name": "Khánh Hòa",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "KHANHHOA"
    },
    {
        "id": "91",
        "name": "Kiên Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "KIENGIANG"
    },
    {
        "id": "62",
        "name": "Kon Tum",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "KONTUM"
    },
    {
        "id": "12",
        "name": "Lai Châu",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "LAICHAU"
    },
    {
        "id": "68",
        "name": "Lâm Đồng",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "LAMDONG"
    },
    {
        "id": "20",
        "name": "Lạng Sơn",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "LANGSON"
    },
    {
        "id": "10",
        "name": "Lào Cai",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "LAOCAI"
    },
    {
        "id": "80",
        "name": "Long An",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "LONGAN"
    },
    {
        "id": "36",
        "name": "Nam Định",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "NAMDINH"
    },
    {
        "id": "40",
        "name": "Nghệ An",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "NGHEAN"
    },
    {
        "id": "37",
        "name": "Ninh Bình",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "NINHBINH"
    },
    {
        "id": "58",
        "name": "Ninh Thuận",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "NINHTHUAN"
    },
    {
        "id": "25",
        "name": "Phú Thọ",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "PHUTHO"
    },
    {
        "id": "54",
        "name": "Phú Yên",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "PHUYEN"
    },
    {
        "id": "44",
        "name": "Quảng Bình",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "QUANGBINH"
    },
    {
        "id": "49",
        "name": "Quảng Nam",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "QUANGNAM"
    },
    {
        "id": "51",
        "name": "Quảng Ngãi",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "QUANGNGAI"
    },
    {
        "id": "22",
        "name": "Quảng Ninh",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "QUANGNINH"
    },
    {
        "id": "45",
        "name": "Quảng Trị",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "QUANGTRI"
    },
    {
        "id": "94",
        "name": "Sóc Trăng",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "SOCTRANG"
    },
    {
        "id": "14",
        "name": "Sơn La",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "SONLA"
    },
    {
        "id": "72",
        "name": "Tây Ninh",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "TAYNINH"
    },
    {
        "id": "34",
        "name": "Thái Bình",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "THAIBINH"
    },
    {
        "id": "19",
        "name": "Thái Nguyên",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "THAINGUYEN"
    },
    {
        "id": "38",
        "name": "Thanh Hóa",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "THANHHOA"
    },
    {
        "id": "46",
        "name": "Thừa Thiên Huế",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "THUATHIENHUE"
    },
    {
        "id": "82",
        "name": "Tiền Giang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "TIENGIANG"
    },
    {
        "id": "84",
        "name": "Trà Vinh",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "TRAVINH"
    },
    {
        "id": "8",
        "name": "Tuyên Quang",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "TUYENQUANG"
    },
    {
        "id": "86",
        "name": "Vĩnh Long",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "VINHLONG"
    },
    {
        "id": "26",
        "name": "Vĩnh Phúc",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "VINHPHUC"
    },
    {
        "id": "15",
        "name": "Yên Bái",
        "type": 2,
        "typeText": "Tỉnh",
        "slug": "YENBAI"
    }
]