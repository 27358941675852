import React, { useRef } from "react";
import Webcam from "react-webcam";
import {Button, Modal} from "antd";
import {CameraOutlined, CloseOutlined} from "@ant-design/icons";
import {IconCameraRotate} from "../../Icons";

const Camera = ({open, onClose, onPicture}) => {
    const defaultVideoConstraints = {
        width: 1920, // Desired capture width (e.g., 1080p)
        height: 1080, // Desired capture height

        facingMode: "environment"
    };

    const webcamRef = useRef(null);
    const [videoConstraints, setVideoConstraints] = React.useState(defaultVideoConstraints);

    const capturePhoto = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        onPicture(imageSrc);
        handleClose()
    }, [webcamRef]);


    const handleClose = () => {
        onClose();
    };

    const cameraRotate = () => {
        setVideoConstraints({
            ...videoConstraints,
            facingMode: videoConstraints.facingMode === 'user' ? 'environment' : 'user'
        })
    }

    const CameraFooter = () => {
        return (
            <div className="d-flex justify-content-between">
                    <Button icon={<CloseOutlined />} onClick={handleClose}>Huỷ</Button>
                    <Button type="primary" icon={<CameraOutlined />} onClick={capturePhoto}>Chụp</Button>
                    <Button icon={<IconCameraRotate style={{width: '1.3em', height: '1.3em'}}/>} onClick={cameraRotate}/>
            </div>
        )
    }

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            closable={false}
            footer={<CameraFooter />}
        >
            <Webcam
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/png"
                forceScreenshotSourceSize={true}
                imageSmoothing={true}
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                style={{ width: 320, height:420 }} // Inline styles
            />
        </Modal>
    );
};

export default Camera;
