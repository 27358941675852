import {Button, Skeleton, Table, App, Select, Tag, Checkbox, Row, Col, Space, Card} from "antd";
import {useEffect, useState} from "react";
import InputDrawer from "./InputDrawer";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import api from "../../Helpers/api-helper";
import {DeleteOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import Search from "antd/es/input/Search";
import {UseSiteConfig} from "../../Helpers/config-helper";

const MasterDataComponent = ({section}) => {
    const {modal} = App.useApp();
    const [sectionData, setSectionData] = useState(null);
    const [inputOpen, setInputOpen] = useState(false);
    const sectionList = ['expense_list', 'season_list', 'account_list', 'personnel_list'];


    const siteConfig = UseSiteConfig(state => state.siteConfig);

    const setLoading = UseLoading(state => state.setLoading);
    const [tableData, setTableData] = useState([]);
    const [initialValues, setInitialValues] = useState(null);

    const roles = [
        {key: 'kt', value: 'Kế Toán', tag: <Tag color="blue">Kế Toán</Tag>},
        {key: 'tc', value: 'Tài Chính', tag: <Tag color="green">Tài Chính</Tag>},
    ]

    const fetchData = (target) => {
        if (sectionData || target) {
            const sectionKey = target || sectionData.key;
            setLoading(true);

            api.get(`/master/${sectionKey}/list`).then((response) => {
                setTableData(response)
            }).finally(() => {
                setLoading(false)
            });
        }
    }

    const deleteData = (_id, target) => {

        modal.confirm({
            title: 'Xoá dữ liệu',
            icon: <ExclamationCircleFilled/>,
            content: (<span>Có chắc chắn xoá dữ liệu này không?</span>),
            okText: 'Đồng ý xoá',
            okType: 'danger',
            cancelText: 'Bỏ qua',
            onOk: () => {
                setLoading(true);
                api.post(`/master/${target}/delete`, {_id}).then(resp => {
                    if (resp) {
                        notify('success', resp);
                        fetchData(target);
                    }
                }).finally(() => {
                    setLoading(false)
                })
            },
            onCancel() {
            },
        });
    }

    const setDataEdit = (data) => {
        setInitialValues(data);
        setInputOpen(true);
    }

    const personnel_permission_control = (value) => {
        return (
            <Select value={value}>
                {
                    roles.map((role, index) => {
                        return (
                            <Select.Option key={index} value={role.key}>{role.value}</Select.Option>
                        )
                    })
                }
            </Select>
        )
    }

    const search_and_setvalues = (search_data) => {
        //validate search_data, must be string, atleast 4 characters and not contain blank space, should be trimed
        if (search_data.length < 4 || search_data.includes(' ')) {
            notify('error', 'Dữ liệu tìm kiếm không hợp lệ, ít nhất 4 ký tự và không có khoảng trắng');
            return;
        }

        setLoading(true)
        api.post('/master/personnel_list/search', {uid: search_data}).then(response => {
            if (response)
                setDataEdit(response)
        }).finally(() => {
            setLoading(false)
        })
    }

    const masterData = {
        expense_list: {
            title: 'Danh sách chi phí',
            key: 'expense_list',
            columns: [
                {
                    title: 'Tên chi phí',
                    dataIndex: 'name',
                    key: 'name',
                    rules:
                        [
                            {
                                required: true,
                                min: 5,
                                message: 'Tên chi phí không hợp lệ, phải có ít nhất 5 ký tự'
                            }
                        ],
                    render: (text, record) => {
                        return (
                            <span className="link-text" onClick={
                                () => {
                                    setDataEdit({
                                        name: record.name,
                                        description: record.description,
                                        _id: record._id
                                    });
                                }
                            }>{record.name}</span>
                        )
                    }
                }, {
                    title: 'Mô tả chi phí',
                    dataIndex: 'description',
                    key: 'description',
                    rules:
                        [
                            {
                                required: true,
                                min: 5,
                                message: 'Mô tả chi phí không hợp lệ, phải có ít nhất 5 ký tự'
                            }
                        ],
                },
                {
                    title: '#',
                    hidden: true,
                    align: 'right',
                    render: (text, record) => {
                        return (
                            <Button danger size="small" icon={<DeleteOutlined/>} onClick={
                                () => {
                                    deleteData(record._id, 'expense_list')
                                }
                            }/>
                        )
                    }
                }
            ]
        },

        season_list: {
            title: 'Danh sách mùa vụ',
            key: 'season_list',
            columns: [
                {
                    title: 'Tên mùa vụ',
                    dataIndex: 'name',
                    key: 'name',
                    rules:
                        [
                            {
                                required: true,
                                min: 5,
                                message: 'Tên mùa vụ không hợp lệ, phải có ít nhất 3 ký tự'
                            }
                        ],
                    render: (text, record) => {
                        return (
                            <span className="link-text" onClick={
                                () => {
                                    setDataEdit({
                                        name: record.name,
                                        description: record.description,
                                        _id: record._id
                                    });
                                }
                            }>{record.name}</span>
                        )
                    }
                }, {
                    title: 'Mô tả mùa vụ',
                    dataIndex: 'description',
                    key: 'description',
                    rules:
                        [
                            {
                                required: true,
                                min: 3,
                                message: 'Mô tả mùa vụ không hợp lệ, phải có ít nhất 3 ký tự'
                            }
                        ],
                },
                {
                    title: '#',
                    hidden: true,
                    align: 'right',
                    render: (text, record) => {
                        return (
                            <Button danger size="small" icon={<DeleteOutlined/>} onClick={
                                () => {
                                    deleteData(record._id, 'season_list')
                                }
                            }/>
                        )
                    }
                }
            ]
        },

        account_list: {
            title: 'Danh sách tài khoản thanh toán',
            key: 'account_list',
            columns: [
                {
                    title: 'Mã tài khoản',
                    dataIndex: 'name',
                    key: 'name',
                    rules:
                        [
                            {
                                required: true,
                                min: 2,
                                message: 'Tên tài khoản không hợp lệ, phải có ít nhất 2 ký tự'
                            }
                        ],
                    render: (text, record) => {
                        return (
                            <span className="link-text" onClick={
                                () => {
                                    setDataEdit({
                                        name: record.name,
                                        description: record.description,
                                        _id: record._id
                                    });
                                }
                            }>{record.name}</span>
                        )
                    }
                }, {
                    title: 'Tên tài khoản',
                    dataIndex: 'description',
                    key: 'description',
                    rules:
                        [
                            {
                                required: true,
                                min: 3,
                                message: 'Tên tài khoản không hợp lệ, phải có ít nhất 3 ký tự'
                            }
                        ],
                },
                {
                    title: '#',
                    hidden: true,
                    align: 'right',
                    render: (text, record) => {
                        return (
                            <Button danger size="small" icon={<DeleteOutlined/>} onClick={
                                () => {
                                    deleteData(record._id, 'account_list')
                                }
                            }/>
                        )
                    }
                }
            ]
        },

        personnel_list: {
            title: 'Danh sách nhân viên kế toán',
            key: 'personnel_list',
            columns: [
                {
                    title: 'Tìm mã nhân viên',
                    hiddenFromTable: true,
                    hiddenWhenHasId: true,
                    key: 'uid_search',
                    render: (text, record) => {
                        return (
                            <span className="link-text" onClick={
                                () => {
                                    setDataEdit({
                                        name: record.name,
                                        description: record.description,
                                        _id: record._id
                                    });
                                }
                            }>{record.name}</span>
                        )
                    },
                    control: (
                        <Search
                            placeholder="Nhập mã Nhân viên để tìm kiếm"
                            onSearch={search_and_setvalues}
                        />
                    )
                },
                {
                    title: 'Mã nhân viên',
                    dataIndex: 'uid',
                    key: 'uid',
                    readonly: true
                },
                {
                    title: 'Tên nhân viên',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    readonly: true,
                    render: (text, record) => (
                        <span className="link-text" onClick={() => {
                            setDataEdit(record);
                        }}>{record.fullname}</span>
                    )
                },
                {
                    title: 'Vị trí',
                    dataIndex: 'position_title',
                    key: 'position_title',
                    readonly: true
                },
                {
                    title: 'Phòng ban',
                    dataIndex: 'department',
                    key: 'department',
                    readonly: true
                },
                {
                    title: 'Chức năng',
                    dataIndex: 'role',
                    key: 'department',
                    align: 'center',
                    rules: [
                        {
                            required: true,
                            message: 'Vui lòng chọn chức năng cho nhân viên'
                        }
                    ],
                    render: (text, record) => {
                        //return personnel_permission_control(record.role);
                        return (
                            <span>{roles.find(item => item.key === record.role)?.tag}</span>
                        )
                    },
                    control: personnel_permission_control()
                },
                {
                    title: 'Xử lý',
                    dataIndex: 'accept_document',
                    key: 'department',
                    align: 'center',
                    rules: [
                        {
                            required: true,
                            message: 'Vui lòng chọn nhân viên này có nhận chứng từ hay không'
                        }
                    ],
                    render: (text, record) => {
                        //return personnel_permission_control(record.role);
                        return (
                            <span>{record.accept_document ? <Tag color="red">Có</Tag> :
                                <Tag color="cyan">Không</Tag>}</span>
                        )
                    },
                    control: <Select>
                        <Select.Option key="0" value={true}>Có</Select.Option>
                        <Select.Option key="1" value={false}>Không</Select.Option>
                    </Select>
                },
                {
                    title: 'Quyền xem/xử lý danh mục hồ sơ',
                    hiddenFromTable: true,
                    dataIndex: 'permission',
                    control: (
                        <Checkbox.Group>
                            <Card
                                bordered="true"
                            >

                                {/*Display in a grid with 2 columns using Col*/}
                                <Row>
                                    {siteConfig.expense_type?.map((item, index) => {
                                        return (
                                            <Col span={24}
                                                 className="mb-2"
                                                 key={index}
                                            >
                                                <Checkbox
                                                    key={index}
                                                    value={item.name}
                                                    className="link-text"
                                                >{item.name}</Checkbox>
                                            </Col>
                                        )
                                    })}

                                </Row>
                            </Card>
                        </Checkbox.Group>
                    )
                },
                {
                    title: '#',
                    hidden: true,
                    align: 'right',
                    render: (text, record) => {
                        return (
                            <Button danger size="small" icon={<DeleteOutlined/>} onClick={
                                () => {
                                    deleteData(record._id, 'personnel_list')
                                }
                            }/>
                        )
                    }
                }
            ]
        }
    }

    useEffect(() => {
        //Check if section is valid
        if (sectionList.includes(section)) {
            setSectionData(masterData[section])
        } else {
            setSectionData(null);
        }
    }, [section])

    useEffect(() => {
        fetchData();
    }, [sectionData]);

    if (!sectionData) {
        return (
            <Skeleton active/>
        );
    }

    const closeDrawerInput = () => {

        setInputOpen(false);
        setInitialValues(null)
    }

    return (
        <div>
            <div className="mb-3 text-l text-bold items-start-end">
                <span>{sectionData.title}</span>
                <Button type="primary" onClick={setInputOpen}>+ Thêm mới</Button>
            </div>
            <Table
                columns={sectionData.columns.filter(item => !item.hiddenFromTable)}
                dataSource={tableData}
            />
            <InputDrawer
                open={inputOpen}
                setOpen={setInputOpen}
                title={sectionData.title}
                onClose={closeDrawerInput}
                section={section}
                initialValues={initialValues}
                formSchema={sectionData.columns}
                dataKey={sectionData.key}
                onReload={fetchData}
                siteConfig
            />
        </div>
    )
}

export default MasterDataComponent;