import { useEffect } from 'react';

function useKeyPress(targetKey, action) {
    // Create a function to track the key state
    const handleKeyPress = (event) => {
        if (event.key === targetKey) {
            action()
        }
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [targetKey]); // Only re-run effect if targetKey changes
}

export default useKeyPress;
