import {jwtDecode} from "jwt-decode";
import Cookies from 'js-cookie';
import {UseLoading} from "./loading-spinner-helper";
import api from "./api-helper";
import {useUserInfo} from "./user-session-helper";
import {updateMenu} from "../Components/Menu";
import {TEST} from "./config-helper";

export const AppId = 'FL_APP_KT_THANHTOAN';
const redirect_url = TEST ? 'http://localhost:3001' : 'https://thanhtoan.forlife.vn'

export const clearTokenAndRedirect = () => {

    if (TEST) return

    const url = window.location.href;
    if (url.includes('localhost') || url.includes('192.168.168.59')){
        ///window.localStorage.setItem('FL_APP_KT_THANHTOAN', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZpbmgiLCJ0eXBlIjoiYXQiLCJpYXQiOjE3MTY3Nzg4MDMsImV4cCI6MTcxNzM4MzYwM30.1TQ2Fi936hwtwAPMtTvk1BSrEIkWe_O2Zt3ipk4r-7I");
        //reload page
        setTimeout(() => {
            //window.location.reload();
        }, 1000);

        return;
    }

    window.localStorage.removeItem(AppId);
    //Token Expires, redirect to login site
    if (!TEST)
        window.location='https://home.forlife.vn/?redirect_url=' + redirect_url;
}

export const getAppToken = () => {

    const loading = UseLoading.getState();
    const updateAfterGetToken = (jwt) => {
        //Update User State Info
        const setUserInfo = useUserInfo.getState();

        setUserInfo.setInfo(jwt.uid, jwt.role, jwt.level, jwt.name, jwt.department, jwt.mobile, jwt.position_title);
        if (TEST)
            setUserInfo.setTest(true);
        updateMenu();
    }

    const getAppTokenFromBackend = (siteCookieToken) => {
        loading.setLoading(true);
        api.post('/authenticate', {token: siteCookieToken}).then(response => {

            try{
                const jwt = jwtDecode(response);
                updateAfterGetToken(jwt);
                setLocalToken(AppId, response);
            } catch (err) {
                //console.log("Redirect 1")
                clearTokenAndRedirect();
                //notify('error', 'Lỗi token (59)');
            }

        }).finally(() => {
            loading.setLoading(false)
        });
    }

    //get AppToken first, then decode. If AppToken not found or invalid, get siteCookie then authenticate with backend
    let appToken = window.localStorage.getItem(AppId);
    if (appToken){ //AppToken found
        try {
            //Decode token
            let jwt = jwtDecode(appToken)

            if (!jwt?.uid || !jwt?.exp) {
                //console.log("Redirect 2")
                clearTokenAndRedirect();
                return;
            }

            if (jwt.exp < Date.now() / 1000) {
                //console.log("Redirect 3")
                clearTokenAndRedirect();
                return;
            }

            //Token is valid, do post login stuff
            updateAfterGetToken(jwt);

            return;
        } catch (err) {
            //console.log("Redirect 4")
            clearTokenAndRedirect();
        }
    }

    //AppToken not found or Invalid, get siteCookie then authenticate with backend
    const siteCookieToken = getSiteCookieToken();

    if (!siteCookieToken){
        //redirect to FLHome if cookie not found
        //redirect goes here and remove this line
        //setCookieToken('token', siteCookieToken);
        clearTokenAndRedirect();
    } else {
        //siteCookieToken found, get AppToken from backend
        getAppTokenFromBackend(siteCookieToken);
    }

}

export const jwtCheck = () => {
    try {
        const token = jwtDecode(window.localStorage.getItem(AppId));
        return token;
    } catch (e){
        //console.log("jwtCheck Error:", e.message)
        return false;
    }
}

export const getSiteCookieToken = () => {
    const siteCookieToken = Cookies.get('token');

    //console.log(siteCookieToken);

    return siteCookieToken
    //return window.localStorage.getItem('token');
}

export const getAPIAppToken = () => {
    return 'Bearer ' + window.localStorage.getItem(AppId);
}
export const setLocalToken = (name, value) => {
    //Cookies.set(name, value)
    window.localStorage.setItem(name, value);
}