import {useUserInfo} from "../../Helpers/user-session-helper";
import {Button} from "antd";

const TestSwitch = () => {
    const {setTest} = useUserInfo();
    const userInfo = useUserInfo();

    const switchMode = () => {
        //get userInfo mode
        if (!userInfo?.test){
            setTest(true)
        } else {
            setTest(false)
        }
    }

    return(
        <>
        <Button
            type="primary"
            onClick={switchMode}
        >
            Chuyển sang chế độ {userInfo?.test ? "PROD" : "TEST"}
        </Button>
        </>
    )
}

export default TestSwitch