import {useEffect, useState} from "react";
import {Button} from "antd";

const GraceTimeButton = ({inputGraceTime, onClickAction, text, disabled, icon, reset}) => {
    const [countdown, setCountdown] = useState(0);
    const [isCounting, setIsCounting] = useState(false);

    useEffect(() => {

        setIsCounting(false);
        setCountdown(0);

    }, [reset]);

    useEffect(() => {
        if (isCounting) {
            const timer = setTimeout(() => {
                if (countdown <= 1) {
                    setIsCounting(false);
                    onClickAction(); // Dispatch your action here
                    setCountdown(0);
                } else {
                    setCountdown(countdown - 1);
                }
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [countdown, isCounting, onClickAction]);

    const handleButtonClick = () => {
        if (!isCounting) {
            setIsCounting(true);
            setCountdown(inputGraceTime);
        } else {
            setIsCounting(false);
            setCountdown(0);
        }
    };

    return (
        <div>
            <Button
                type="primary"
                danger={isCounting}
                onClick={handleButtonClick}
                icon={icon}
                disabled={disabled}
            >
                {isCounting ? `${text} (${countdown})` : text}
            </Button>
        </div>
    );
};

export default GraceTimeButton;