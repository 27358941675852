import {Col, Form, Modal, Row, Input, Space, Button, DatePicker} from "antd";
import {formatNumber, readNumber} from "../../Helpers/number-helper";
import {useEffect, useState} from "react";
import {notify} from "../../Helpers/toast-helper";
import api from "../../Helpers/api-helper";
import {IconMoney} from "../Icons";

import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */


const {TextArea} = Input;
const PaymentConfirm = ({open, onClose, confirmData, onReload}) => {
    const [form] = Form.useForm();
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = (ids, note, payment_date) => {
        //if ids is string, convert to array
        if (typeof ids === 'string') {
            ids = [ids];
        }

        setSubmitDisabled(true);
        api.post('/request/process/payment/confirm', {_ids: ids, note, payment_date}).then((response) => {
            if (response) {
                notify('info', response);
                onReload();
                //close modal
                handleClose();
            }
        }).finally(() => {
            setSubmitDisabled(false);
        });
    }

    const handleConfirm = () => {
        //get note from form

        //validate form money_transfer_at
        form.validateFields(['money_transfer_at']).then((values) => {
            const {note, money_transfer_at} = form.getFieldsValue();
            //call api to confirm payment
            handleSubmit(confirmData._id, note, money_transfer_at);
        }).catch((err) => {
            console.log(err)
            notify('error', 'Có lỗi xảy ra khi xác nhận thanh toán');
        });
    }

    const ModalFooter = () => {
        return (
            <Space>
                <Button onClick={handleClose}>Bỏ qua</Button>
                <Button onClick={handleConfirm} type="primary" disabled={submitDisabled}>Xác nhận</Button>
            </Space>
        )
    }

    useEffect(() => {
        if (confirmData) {
            form.setFieldsValue({
                note: confirmData?.note
            });

            setSubmitDisabled(confirmData?.status);
        }

        return () => {
            form.resetFields();
        }
    }, [open]);

    return (
        <Modal
            title="Xác nhận thanh toán"
            centered
            open={open}
            onCancel={handleClose}
            footer={<ModalFooter/>}
        >
            <Row className="mt-4">
                <Col span={8}>
                    <span>Mã yêu cầu</span>
                </Col>
                <Col span={16}>
                    <span>{confirmData?.request_code}</span>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={8}>
                    <span>Thụ hưởng</span>
                </Col>
                <Col span={16}>
                    <span>{confirmData?.receiver_name}</span>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={8}>
                    <span>Số tiền</span>
                </Col>
                <Col span={6}>
                    <span>{formatNumber(confirmData?.amount)}</span>
                </Col>
                <Col span={5}>
                    <span>{confirmData?.currency_code}</span>
                </Col>
                <Col span={5}>
                    <span title="Tỉ giá"><IconMoney/> {formatNumber(confirmData?.exchange_rate)}</span>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={8}>
                    <span>Tài khoản đi tiền</span>
                </Col>
                <Col span={16}>
                    <span>{confirmData?.transfer_from_account}</span>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={8}>
                    <span>Số tiền bằng chữ</span>
                </Col>
                <Col span={16}>
                    <span>{readNumber(confirmData?.amount, confirmData?.currency_code)}</span>
                </Col>
            </Row>
            <Form
                className="mt-3"
                form={form}
                labelCol={{
                    span: 8,
                    style: {
                        textAlign: 'left'
                    }
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <Form.Item
                    label="Ghi chú"
                    name="note"
                >
                    <TextArea
                        style={{
                            resize: 'none',
                        }}
                    />
                </Form.Item>
                <Row className="mt-3">
                    <Col span={8}>
                        <span>Cấp nguồn bởi:</span>
                    </Col>
                    <Col span={16}>
                        <span>{confirmData?.created_by_name}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={8}>
                        <span>Ngày TC xác nhận</span>
                    </Col>
                    <Col span={16}>
                        <span>{confirmData?.payment_date ? dayjs(confirmData?.payment_date).format('DD-MM-YYYY HH:mm:ss') : null}</span>
                    </Col>
                </Row>
                    <Form.Item
                        label="Ngày KT xác nhận chi"
                        name="money_transfer_at"
                        className="mt-2"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn ngày xác nhận chi'
                            }
                        ]}
                    >
                        <DatePicker
                            style={{width: '100%'}}
                            format="DD-MM-YYYY"
                        />
                    </Form.Item>
                {/*
                <div style={{display: confirmData?.status ? 'block' : 'none'}} className="text-success">
                    <Row className="mt-3">
                        <Col span={8}>
                            <span>Xác nhận đi tiền:</span>
                        </Col>
                        <Col span={16}>
                            <span>{confirmData?.money_transfer_by_name}</span>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={8}>
                            <span>Ngày xác nhận</span>
                        </Col>
                        <Col span={16}>
                            <span>{confirmData?.money_transfer_at ? dayjs(confirmData?.money_transfer_at).format('DD-MM-YYYY HH:mm:ss') : null}</span>
                        </Col>
                    </Row>
                </div>
                */}
            </Form>
        </Modal>
    )
}

export default PaymentConfirm