import {Button, Flex, Grid, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ModalFormWrapper from "./ModalFormWrapper";
import {useEffect, useState} from "react";
import {cleanUpTmp} from "./Step2DetailSheet/helper";
import api from "../../Helpers/api-helper";
import dayjs from "dayjs";
import {useTravelExpenseStore} from "./stores";
import bodyScrollLock from './scroll-lock'
const { useBreakpoint } = Grid;

const TravelExpense = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [expenseList, setExpenseList] = useState([])
    const [currentExpense, setCurrentExpense] = useState(null)
    const [isMobile, setIsMobile] = useState(false)

    const screens = useBreakpoint();

    const handleModalOpen = () => {
        //Detect if on mobile and disable body scroll
        if (isMobile) bodyScrollLock.enable()
        setModalOpen(true)
    }

    const handleModalClose = () => {
        //Detect if on mobile and enable body scroll
        if (isMobile) bodyScrollLock.disable()

        setModalOpen(false);
        setCurrentExpense(null)
        cleanUpTmp()

    }

    const {setCompanyList} = useTravelExpenseStore()

    const columns = [
        {
            title: 'STT',
            key: 'stt',
            render: (text, record, index) => index + 1,
            align: 'center'
        },
        {
            title: 'Tên hồ sơ',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <Button type="link" onClick={() => {
                setCurrentExpense(record._id)
                handleModalOpen()
            }}>{text}</Button>
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            key: 'created_at',
            render: (text) => dayjs(text).format('DD/MM/YYYY')
        },
        {
            title: 'Cập nhật',
            dataIndex: 'updateAt',
            key: 'status',
            render: (text) => dayjs(text).format('DD/MM/YYYY')
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Flex>
                    <Button type="link">Xem</Button>
                    <Button type="link">Sửa</Button>
                    <Button type="link">Xóa</Button>
                </Flex>
            ),
        },
    ];

    const getData = () => {
        api.get('/travel/expense/list').then(response => {
            if (response) {
                //add key to each item
                response.forEach((item, index) => {
                    item.key = index
                })
                setExpenseList(response)
            }
        })
    }

    const getCompanyList = () => {
        api.get('/master/company/list').then(response => {
            if (response) {
                setCompanyList(response)
            }
        })
    }

    useEffect(() => {
        getData()
        getCompanyList();
    }, [])

    useEffect(() => {
        setIsMobile(!screens.md)
    }, [screens])

    return (
        <div>
            <Flex justify="space-between" align="baseline">
                <h1>Hồ sơ công tác phí</h1>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={handleModalOpen}
                >
                    Tạo hồ sơ
                </Button>
            </Flex>
            <Table
                columns={columns}
                dataSource={expenseList}
                rowKey="_id"
            />
            <ModalFormWrapper open={modalOpen} close={handleModalClose} expenseId={currentExpense} reloadExpenseList={getData}/>
        </div>
    );
}

export default TravelExpense;