import {Descriptions} from "antd";

const ExpenseLineDisplay = ({expense}) => {
    return (
        <Descriptions
            items={[
                {
                    key: 1,
                    label: 'Mã NV',
                    children: expense?.uid
                },
                {
                    key: 2,
                    label: 'Tên NV',
                    children: expense?.fullname
                },
                {
                    key: 3,
                    label: 'Ngày đi',
                    children: expense?.travelDate?.format('DD/MM/YYYY') || null,
                    labelStyle: {color: !expense?.travelDate ? '#dc3545' : 'inherit'}
                },
                {
                    key: 4,
                    label: 'Điểm đi',
                    children: expense?.departure,
                    labelStyle: {color: !expense?.departure ? '#dc3545' : 'inherit'}

                },
                {
                    key: 5,
                    label: 'Điểm đến',
                    children: expense?.destination,
                    labelStyle: {color: !expense?.destination ? '#dc3545' : 'inherit'}
                },
                {
                    key: 6,
                    label: 'Khoảng cách',
                    children: expense?.distance,
                    labelStyle: {color: !expense?.distance ? '#dc3545' : 'inherit'}
                },
                {
                    key: 7,
                    label: 'Phương tiện',
                    children: expense?.vehicle,
                    labelStyle: {color: !expense?.vehicle ? '#dc3545' : 'inherit'}
                },
                {
                    key: 8,
                    label: 'Số vé/Nhà xe',
                    children: expense?.ticketNumber
                },
                {
                    key: 9,
                    label: 'Số tiền',
                    children: expense?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    labelStyle: {color: !expense?.amount ? '#dc3545' : 'inherit'}
                },
                {
                    key: 10,
                    label: 'Diễn giải',
                    children: expense?.note,

                }
            ]}
        />
    )
}

export default ExpenseLineDisplay;