import {Button, Result, Space} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const FormQuestion = ({question, onYes, onNo, action}) => {
    return (
        <Result
            icon={<QuestionCircleOutlined/>}
            title={question}
            extra={
                <Space>
                    <Button
                        style={{width: 100}}
                        onClick={() => action(parseInt(onYes))}
                    >
                        <strong>Có</strong>
                    </Button>
                    <Button
                        style={{width: 100}}
                        onClick={() => action(parseInt(onNo))}
                    >
                        <strong>Không</strong>
                    </Button>
                </Space>
            }
        />
    )
}