import {useEffect, useState} from "react";
import {
    IconDestination, IconDone, IconQuestionMark, IconSleeping,
    IconTravel, IconTravelTime,
    IconUserCard
} from "../../../Icons";
import {Button, Flex, Grid, Modal, Tabs, App} from "antd";
import {
    ArrowLeftOutlined,
    LeftOutlined,
    RightOutlined, SaveOutlined
} from "@ant-design/icons";
import {canSubmit, modalMinWidth, useRequestPaymentLineStore, useTravelExpenseStore} from "../../stores";
import {EmployeeInformation} from "./FormsContent/EmployeeInformation";
import {TravelPlan} from "./FormsContent/TravelPlan";
import {FormQuestion} from "./FormsContent/FormQuestion";
import {LivingExpense} from "./FormsContent/LivingExpense";
import {TransportationExpense} from "./FormsContent/TransportationExpense";
import {TravelTime} from "./FormsContent/TravelTime";
import {Summary} from "./FormsContent/Summary";

const {useBreakpoint} = Grid;

const ExpenseFormWizard = ({open, close, editingRow}) => {

    const {message} = App.useApp();
    const [currentStep, setCurrentStep] = useState(0);
    const [days, setDays] = useState(null)

    const bodyHeight = '400px';
    const breakPoint = useBreakpoint();
    const tabIconSize = {
        width: breakPoint.xs ? 14 : 22,
        height: breakPoint.xs ? 14 : 22
    }

    const footerHeight = 30

    const steps = [
        {
            title: '',
            label: '',
            content: <EmployeeInformation title="Thông tin nhân viên"/>,
            key: "0",
            icon: <IconUserCard style={{...tabIconSize, fill: currentStep === 0 ? 'blue' : 'inherit'}}/>,
        },
        {
            title: '',
            label: 'Thời gian/Địa điểm',
            content: <TravelPlan setDays={setDays} />,
            key: "1",
            icon: <IconDestination style={{...tabIconSize, fill: currentStep === 1 ? 'blue' : 'inherit'}}/>
        },
        {
            title: '',
            label: <span>&nbsp;</span>,
            content: <FormQuestion
                question="Thời gian công tác này có chi phí sinh hoạt hoặc lưu trú không?"
                onYes="3"
                onNo="4"
                action={setCurrentStep}
            />,
            icon: <IconQuestionMark style={{...tabIconSize, fill: currentStep === 2 ? 'blue' : 'inherit'}}/>,
            key: "2",
            type: 'question'
        },
        {
            title: '',
            label: 'Phí sinh hoạt và Lưu trú',
            content: <LivingExpense days={days} />,
            key: "3",
            icon: <IconSleeping style={{...tabIconSize, fill: currentStep === 3 ? 'blue' : 'inherit'}}/>
        },
        {
            title: '',
            label: <span>&nbsp;</span>,
            content: <FormQuestion
                question="Thời gian công tác này có chi phí di chuyển hoặc sử dụng xe cá nhân, di chuyển nội vùng hoặc chi phí nước ngoài không?"
                onYes="5"
                onNo="6"
                action={setCurrentStep}
            />,
            icon: <IconQuestionMark style={{...tabIconSize, fill: currentStep === 4 ? 'blue' : 'inherit'}}/>,
            key: "4",
            type: 'question'
        },
        {
            title: '',
            label: 'Chi phí đi lại/Phương tiện',
            content: <TransportationExpense />,
            key: "5",
            icon: <IconTravel style={{...tabIconSize, fill: currentStep === 5 ? 'blue' : 'inherit'}}/>
        },
        {
            title: '',
            label: 'Thời gian di chuyển',
            content: <TravelTime />,
            key: "6",
            icon: <IconTravelTime style={{...tabIconSize, fill: currentStep === 6 ? 'blue' : 'inherit'}}/>
        },
        {
            title: '',
            label: 'Tổng kết',
            content: <Summary action={setCurrentStep}/>,
            key: "7",
            icon: <IconDone style={{...tabIconSize, fill: currentStep === 7 ? 'blue' : 'inherit'}}/>
        },
    ]

    const {line, setData, resetData} = useRequestPaymentLineStore();
    const {addPaymentRequestDetail, updatePaymentRequestDetail} = useTravelExpenseStore();

    useEffect(() => {
        if (!open) {
            setCurrentStep(0)
            resetData();
        }

    }, [open])

    const handleClose = () => {
        resetData();
        close();
    }

    const handleSave = () => {
        if (!canSubmit(line)) {
            message.error('Biểu mẫu chưa đầy đủ dữ liệu. Vui lòng nhập đủ thông tin.')
            return;
        }

        console.log(line)

        if (!editingRow) {
            addPaymentRequestDetail(line);
        } else {
            updatePaymentRequestDetail(editingRow, line)
        }
        handleClose()
    }

    const footer = () => {
        const isQuestion = steps[currentStep]?.type === 'question'

        return (
            <div
                style={{
                    height: footerHeight,
                }}
            >
                <Flex
                    justify="space-between"
                    align="start"
                    className="mt-3 mb-2"
                    //style={{visibility: steps[currentStep].type === 'question' ? 'hidden' : 'visible'}}
                    //style={{display: steps[currentStep].type === 'question' ? 'none' : 'flex'}}
                >
                    {isQuestion ?
                        <Button
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => setCurrentStep(currentStep - 1)}
                            type="text"
                        />
                        : <>
                            <Button
                                icon={<LeftOutlined/>}
                                onClick={() => setCurrentStep(currentStep - 1)}
                                disabled={currentStep === 0}
                            >
                                Quay lại
                            </Button>
                            {currentStep !== steps.length - 1 ?
                            <Button
                                type="primary"
                                onClick={() => setCurrentStep(currentStep + 1)}
                                icon={<RightOutlined/>}
                                disabled={currentStep === steps.length - 1}
                            >
                                Tiếp tục
                            </Button>
                            : <Button
                                type="primary"
                                onClick={handleSave}
                                icon={<SaveOutlined />}
                                disabled={false}
                            >
                                Lưu dữ liệu
                            </Button>
                            }
                        </>}
                </Flex>
            </div>
        )
    }

    return (
        <Modal
            title="Expense Form Wizard"
            open={open}
            onCancel={handleClose}
            maskClosable={false}
            style={{
                minWidth: modalMinWidth
            }}
            footer={footer}
        >
            <div style={{height: bodyHeight, maxHeight: bodyHeight, minHeight: bodyHeight}}>
                <Tabs
                    defaultActiveKey="0"
                    activeKey={currentStep.toString()}
                    size={breakPoint.xs ? 'small' : 'large'}
                    onTabClick={(key) => setCurrentStep(parseInt(key))}
                    items={steps.map(step => {
                        return {
                            key: step.key,
                            label: '',
                            icon: step.icon,
                            children: step.content
                        }
                    })}
                />
            </div>
        </Modal>
    )

}

export default ExpenseFormWizard;