import {canSubmit, dataCheck, travelExpenseDetailLines, useRequestPaymentLineStore} from "../../../stores";
import {useEffect} from "react";
import {Button, List, Popover, Space} from "antd";
import {IconExpense, IconIncome, IconSuitcase, IconTravel, IconUserCard} from "../../../../Icons";
import {ExclamationCircleOutlined, RightOutlined} from "@ant-design/icons";
import {numberFormat, parseNumber} from "../../../../../Helpers/common-helper";

export const Summary = ({action}) => {
    const {line} = useRequestPaymentLineStore();

    const listIconSize = {
        width: 24,
        height: 24
    }

    const ActionButton = ({step}) => {
        return (
            <Button
                icon={<RightOutlined />}
                type="link"
                onClick={() => action(step)}
            />
        )
    }

    const dataDisplay = (data) => {
        return data ? data : ''
    }

    const ListExtra = ({requiredFields, step, isJoinCondition = false}) => {
        const isValid = !isJoinCondition ? dataCheck(line, requiredFields) : (dataCheck(line, [requiredFields[0]]) || dataCheck(line, [requiredFields[1]]))

        //console.log(requiredFields, isValid, line)
        return (
            <Space>
                {!isValid ?
                    <Popover content="Dữ liệu chưa đầy đủ" arrow={false}>
                        <ExclamationCircleOutlined style={{color: 'red'}}/>
                    </Popover>
                    : null}
                <ActionButton step={step}/>
            </Space>
        )
    }

    return (
        <List
            itemLayout="horizontal"
            size="small"
        >
            <List.Item
                actions={[
                    <ListExtra requiredFields={travelExpenseDetailLines.step0.fields} step={0}/>
                ]}
            >
                <List.Item.Meta
                    style={{alignItems: 'center'}}
                    avatar={<IconUserCard style={{...listIconSize}}/>}
                    title={dataDisplay(line?.fullname)}
                    description={dataDisplay(line?.position)}
                    onClick={() => action(0)}
                />
            </List.Item>

            <List.Item
                actions={[
                    <ListExtra requiredFields={travelExpenseDetailLines.step1.fields} step={1}/>
                ]}
            >
                <List.Item.Meta
                    style={{alignItems: 'center'}}
                    avatar={<IconSuitcase style={{...listIconSize}}/>}
                    title={dataDisplay(line?.departure) + ' - ' + dataDisplay(line?.destination)}
                    description={`${dataDisplay(line?.from_date?.format('DD/MM/YYYY'))} đến ${dataDisplay(line?.to_date?.format("DD/MM/YYYY"))}`}
                    onClick={() => action(1)}
                />
            </List.Item>

            <List.Item
                actions={[
                    <ListExtra requiredFields={travelExpenseDetailLines.step3.fields} step={3} isJoinCondition={true}/>
                ]}
            >
                <List.Item.Meta
                    style={{alignItems: 'center'}}
                    avatar={<IconExpense style={{...listIconSize}}/>}
                    title="Chi phí sinh hoạt và lưu trú"
                    description={<div className="text-bold text-danger">{numberFormat(parseNumber(line?.allowance_grand_total))} VND</div>}
                    onClick={() => action(3)}
                />
            </List.Item>

            <List.Item
                actions={[
                    <ListExtra requiredFields={travelExpenseDetailLines.step5.fields} step={5} isJoinCondition={true}/>
                ]}
            >
                <List.Item.Meta
                    style={{alignItems: 'center'}}
                    avatar={<IconTravel style={{...listIconSize}}/>}
                    title="Chi phí di chuyển"
                    description={<div className="text-bold text-danger">{numberFormat(parseNumber(line?.transportation_total) )} VND</div>}
                    onClick={() => action(5)}
                />
            </List.Item>

            <List.Item
                actions={[
                    <ActionButton />
                ]}
            >
                <List.Item.Meta
                    style={{alignItems: 'center'}}
                    avatar={<IconIncome style={{...listIconSize}}/>}
                    title="Số tiền đề nghị thanh toán"
                    description={<div className="text-bold text-danger">{numberFormat(parseNumber(line?.transportation_total) + parseNumber(line?.allowance_grand_total) )} VND</div>}
                />
            </List.Item>
        </List>

    )
}