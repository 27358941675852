import {useEffect} from "react";
import Cookies from "js-cookie";
const Logout = () => {
    useEffect(() => {
        window.localStorage.clear();
        Cookies.remove('token', { path: '', domain: '.forlife.vn' });

        setTimeout(() => {
            window.location.href = '/';
        }, 1000);
    }, []);
}

export default Logout;