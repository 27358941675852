import {FormOutlined, OrderedListOutlined, PictureOutlined, UserOutlined} from "@ant-design/icons";
import {create} from "zustand";
import {useState} from "react";
import Step1Personnel from "./Step1Personnel";
import Step2DetailSheet from "./Step2DetailSheet";
import FormMetaData from "./Step3ReimbursementForm/FormMetaData";
import FormData from "./Step3ReimbursementForm/FormData";
import {IconCar} from "../Icons";
import ImageUpload from "./ImageUpload";

export const modalMinWidth = '390px'

export const formSteps = [
    {
        label: "Nhân sự",
        value: 'personnel',
        icon: <UserOutlined/>,
        key: '1',
        description: 'Danh sách nhân sự tham gia chuyến công tác',
        children: <Step1Personnel />
    },
    {
        label: "Di chuyển",
        value: 'detailSheet',
        icon: <IconCar />,
        key: '2',
        description: 'Bảng kê chi phí di chuyển',
        children: <Step2DetailSheet />
    },
    {
        label: "Bảng kê",
        value: 'paymentRequest',
        icon: <OrderedListOutlined />,
        key: '3',
        description: 'Bảng kê chi tiết chi phí công tác',
        children: <FormData />
    },
    {
        label: "Đề nghị thanh toán",
        value: 'paymentRequest',
        icon: <FormOutlined/>,
        key: '4',
        description: 'Mẫu đề nghị thanh toán chi phí công tác',
        children: <FormMetaData />
    },
    {
        label: "File đính kèm",
        value: 'paymentRequest',
        icon: <PictureOutlined/>,
        key: '5',
        description: 'File đính kèm',
        children: <ImageUpload />
    },
]

export const travelExpenseDetailLines = {
    step0: {
        fields: ['fullname', 'position', 'company'],
        isJoinCondition: false
    },
    step1: {
        fields: ['departure', 'destination', 'from_date', 'to_date'],
        isJoinCondition: false
    },
    step3: {
        fields: ['allowance_grand_total', 'transportation_total'],
        isJoinCondition: true
    },

    step5: {
        fields: ['allowance_grand_total', 'transportation_total'],
        isJoinCondition: true
    },
}

export const canSubmit = (data) => {

    let result = true

    for (const key in travelExpenseDetailLines) {
        result = !travelExpenseDetailLines[key].isJoinCondition ? dataCheck(data, travelExpenseDetailLines[key].fields) : (dataCheck(data, [travelExpenseDetailLines[key].fields[0]]) || dataCheck(data, [travelExpenseDetailLines[key].fields[1]]))
        if (!result) {
            break
        }
    }

    return result
}

export const sanitizeData = (values) => {
    //trim all string values from form fields
    const formValues = values
    for (const key in formValues) {
        if (typeof formValues[key] === 'string') {
            formValues[key] = formValues[key].trim()
        }
    }

    return formValues
}

export const useRowSelection = () => {
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);

        const rowSelection = {
            type: 'radio', // Enable radio button selection (single row)
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
                console.log("Selected row keys (hook): ", selectedRowKeys)
                setSelectedRowKeys(selectedRowKeys)
            }
        }

        return [selectedRowKeys, setSelectedRowKeys, rowSelection];
    }
;

export const processFormValues = (thisForm, options = null) => {
    const values = thisForm.getFieldsValue()

    const formValues = sanitizeData(values)
    //Sanitize form values (number)
    for (const [key, value] of Object.entries(formValues)) {
        if (typeof value === 'number') {
            formValues[key] = value
        } else {
            formValues[key] = value?.replace(/\D/g, '')
        }
    }

    if (options) {
        options.forEach(option => {
            if (formValues[option[0]] && formValues[option[1]]) {
                formValues[option[2]] = formValues[option[0]] * formValues[option[1]]
            } else {
                formValues[option[2]] = undefined
            }
        })
    }

    const returnValue = JSON.parse(JSON.stringify(formValues));

    for (const [key, value] of Object.entries(formValues)) {
        if (value) {
            formValues[key] = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    }

    thisForm.setFieldsValue(formValues);
    return returnValue;
}

export const dataCheck = (data, fields = []) => {
    //If any value in the fields array is empty, undefined or null or 0, return false

    for (let i = 0; i < fields.length; i++) {
        if (!data[fields[i]]) {
            return false
        }
    }

    if (Number(data[fields[0]]) === 0) {
        return false
    }

    return true
}

//This state manages the form open/close state, and use to clean up Upload component (ImageUpload) when form is closed
export const useFormState = create((set) => ({
    formOpen: false,
    setFormOpen: (open) => set({formOpen: open}),
}))

//Create a store to manage form data, including employee list, detail sheet, payment request
export const useTravelExpenseStore = create((set, get) => ({
    updated: false,
    //Employee list
    personnel: [],
    addPersonnel: (personnel) => set((prevState) => ({
        personnel: [...prevState.personnel, {
            ...personnel,
            key: Date.now()
        }], updated: true
    })),
    //get personnel by uid or matching string
    getPersonnelById: (uid) => get().personnel.find(item => item.uid === uid),
    //remove personnel by array index
    removePersonnel: (index) => set((prevState) => ({
        personnel: prevState.personnel.filter((_, i) => i !== index),
        updated: true
    })),

    //Detail sheet
    detailSheet: [],
    //add detail sheet line to the first array index
    addDetailSheetLine: (detailSheetLine) => set((prevState) => ({
        detailSheet: [{
            ...detailSheetLine,
            key: Date.now()
        }, ...prevState.detailSheet], updated: true
    })),
    //delete detail sheet line by array index
    deleteDetailSheetLine: (index) => set((prevState) => ({
        detailSheet: prevState.detailSheet.filter((_, i) => i !== index),
        updated: true
    })),
    //update detail sheet line by array index
    updateDetailSheetLine: (index, detailSheetLine) => set((prevState) => {
        prevState.detailSheet[index] = {...detailSheetLine, key: Date.now()}
        return {detailSheet: [...prevState.detailSheet], updated: true}
    }),

    getPlacesList: () => {
        //Combine all departure and destination from detailSheet array
        return [...new Set([...new Set(get().detailSheet.map(item => item?.departure)), ...new Set(get().detailSheet.map(item => item?.destination))].filter(item => item))].map(item => ({value: item}))
    },

    getTransportTypeList: () => {
        return [...new Set(get().detailSheet.map(item => item?.vehicle))].filter(item => item).map(item => ({value: item}))
    },

    getNoteList: () => {
        return [...new Set(get().detailSheet.map(item => item?.note))].filter(item => item).map(item => ({value: item}))
    },

    //Payment request
    paymentRequestDetail: [],
    paymentRequestMeta: {},
    //set paymentRequest meta data
    setPaymentRequestMeta: (paymentRequestMeta) => set({paymentRequestMeta, updated: true}),
    //Add a line to paymentRequestDetail array
    addPaymentRequestDetail: (paymentRequestDetail) => set((prevState) => ({
        paymentRequestDetail: [...prevState.paymentRequestDetail, {
            ...paymentRequestDetail,
            key: Date.now()
        }], updated: true
    })),
    //Remove a line from paymentRequestDetail array by array index
    removePaymentRequestDetail: (index) => set((prevState) => ({
        paymentRequestDetail: prevState.paymentRequestDetail.filter((_, i) => i !== index),
        updated: true
    })),
    //Update a line in paymentRequestDetail array by key prop
    updatePaymentRequestDetail: (key, paymentRequestDetail) => set((prevState) => {
        const index = prevState.paymentRequestDetail.findIndex(item => item.key === key)
        prevState.paymentRequestDetail[index] = {...paymentRequestDetail}
        return {paymentRequestDetail: [...prevState.paymentRequestDetail], updated: true}
    }),

    company: [],
    setCompanyList: (company) => set({company}),
    //get all data
    getData: () => get(),

    //set all data
    setData: (data) => set({...data, updated: false}),

    files: [],
    setFiles: (fileList) => set({files: fileList, updated: true}),
    //Reset form data
    reset: () => set({
        personnel: [],
        detailSheet: [],
        paymentRequestDetail: [],
        paymentRequestMeta: {},
        files: [],
        updated: false
    }),

    setUpdated: (updated) => set({updated})
}));

export const useRequestPaymentLineStore = create((set, get) => ({
    line: {},
    //setData (data): update/replace line with new data
    setData: (data) => set(prev => {
        return {
            ...prev,
            line: {...prev.line, ...data}
        }
    }),

    resetData: () => set({line: []}),
}))
