import {useEffect, useState} from "react";
import {Skeleton, Space} from "antd";
import api from "../../Helpers/api-helper";
import {ClusterOutlined, ContactsOutlined, MailOutlined} from "@ant-design/icons";
import {IconTelegram} from "../Icons";

const UserInformation = ({user}) => {
    const [userInfo, setUserInfo] = useState(null);
    /*
    useEffect(() => {
        if (uid) {
            api.post('/master/user/get', {uid: uid}).then(response => {
                if (response) {
                    setUserInfo(response);
                }
            });
        }

        return () => {
            setUserInfo(null);
        }
    }, [uid]);

     */

    useEffect(() => {
        if (user){
            setUserInfo(user);}

    }, [user])

    const openTelegram = (phone) => {
        //Remove 0 from phone number and add +84
        phone = phone.replace(/^0+/, '+84');
        window.open(`https://t.me/${phone}`);
    }

    return (
        userInfo ?
            <div>
                <Space direction="vertical">
                    <Space direction="horizontal">
                        <ContactsOutlined />
                        <span className="text-bold">{userInfo.position}</span>
                    </Space>
                    <Space direction="horizontal">
                        <ClusterOutlined />
                        <span className="text-bold">{userInfo.department}</span>
                    </Space>
                    <Space direction="horizontal">
                        <MailOutlined />
                        <span className="text-bold">{userInfo.email}</span>
                    </Space>
                    <Space>
                        <IconTelegram />
                        <span className="text-bold link-text" onClick={() => openTelegram(userInfo.phone)}>{userInfo.phone}</span>
                    </Space>
                </Space>
            </div>
            :
            <Skeleton active/>
    )
}

export default UserInformation;